import React from 'react';
import Aux from '../../../HOC/Aux';

const Thirdview = (props) => {

    return (
        <Aux>
            <div class="content">
            <div class="container shipp-dimension" id="pop-up-content">
              <div class="col-md-6 col-sm-6"> 
        	      <div class="dimension-sec pop-img">
       
      
                  <div class="cube visible-xs">
                    <div class="face face__left"><p>{props.length} cm</p></div>
                    <div class="face face__top"><p>{props.width}cm</p></div>
                    <div class="face face__front"><p>{props.height}cm</p></div>
                    <div class="weight"><p>{props.weight} KG</p></div>
                  </div>

                  <div class="dimension hidden-xs ">
                    <div class="length"><p>{props.length}cm</p></div>
                    <div class="width"><p>{props.width}cm</p></div>
                    <div class="height"><p>{props.height}cm</p></div>
                    <div class="weight"><p> {props.weight} KG</p></div>
                  </div> 

                </div>
              </div>
            

              <div class="col-md-6  dimension-sec col-sm-6">
                <form>
           
                <div class="input-group">
                  <div class="col-md-6 col-xs-6"><label class="">{props.type}</label></div>
                  <div class="col-md-6 col-xs-6"><input type="tel" class="selectpicker form-control" placeholder="enter weight" onChange={(e) => props.dimension_handler(e,'weight')}/></div>
                </div><br/>

                <div class="input-group">
                  <div class="col-md-6 col-xs-6"><label class="">Width</label></div>
                  <div class="col-md-6 col-xs-6"><input type="tel" class="selectpicker form-control" placeholder="enter weight" onChange={(e) => props.dimension_handler(e,'width')}/></div>
                </div><br/>

                <div class="input-group">
                  <div class="col-md-6 col-xs-6"><label class="">Length</label></div>
                  <div class="col-md-6 col-xs-6"><input type="tel" class="selectpicker form-control" placeholder="enter weight" onChange={(e) => props.dimension_handler(e,'lenght')}/></div>
                </div><br/>

                <div class="input-group">
                  <div class="col-md-6 col-xs-6"><label class="">Height</label></div>
                  <div class="col-md-6 col-xs-6"><input type="tel" class="selectpicker form-control" placeholder="enter weight" onChange={(e) => props.dimension_handler(e,'height')}/></div>
                </div><br/>

                <div class="input-group">
                  <div class="col-md-6 col-xs-12">
                    <button onClick={props.nextscreen}>Next</button>
                  </div>
                </div>

                </form>    
              </div>



            
            </div>
          </div>
        </Aux>
    );
}

export default Thirdview;