import React from 'react';
import Aux from '../../../HOC/Aux';
import Input_weight from './Add_or_check';

const Weightndimension = (props) => {

  let dimension = '';
  //let dimension_2 = '<input class="selectpicker form-control" placeholder="enter width" onChange={(e) => props.weightndimension(e,"width")}/>' 
  if(props.type === 'weight') {
    console.log("i am in weight dimension")
      //dimension = <Input_weight/>
      dimension = 'weight';
  }

  if(props.type === 'width') {
    console.log("i am in width dimension")
      //dimension = <Input_weight/>
      dimension = 'width';
  } 

  if(props.type === 'lenght') {
    console.log("i am in lenght dimension")
      //dimension = <Input_weight/>
      dimension = 'lenght';
  }

  if(props.type === 'height') {
    console.log("i am in lenght dimension")
      //dimension = <Input_weight/>
      dimension = 'height';
  }

    return (

          <div class="content">
            <div class="container shipp-dimension" id="pop-up-content">
              <div class="col-md-6 col-sm-6"> 
        	      <div class="dimension-sec pop-img">
       
      
                  <div class="cube visible-xs">
                    <div class="face face__left"><p>{props.length} cm</p></div>
                    <div class="face face__top"><p>{props.width}cm</p></div>
                    <div class="face face__front"><p>{props.height}cm</p></div>
                    <div class="weight"><p>{props.weight} KG</p></div>
                  </div>

                  <div class="dimension hidden-xs ">
                    <div class="length"><p>{props.length}cm</p></div>
                    <div class="width"><p>{props.width}cm</p></div>
                    <div class="height"><p>{props.height}cm</p></div>
                    <div class="weight"><p> {props.weight} KG</p></div>
                  </div> 

                </div>
              </div>
            

              <div class="col-md-6  dimension-sec col-sm-6">
                <form>
           
                <div class="input-group">
                  <div class="col-md-6 col-xs-12"><label class="">{props.type}</label></div>
                  <div class="col-md-6 col-xs-12"><input type="tel" class="selectpicker form-control" placeholder="enter weight" onChange={(e) => props.dimension_handler(e,`${dimension}`)}/></div>
                    
                </div><br/>


                <div class="input-group">
                  <div class="col-md-6 col-xs-12">
                    <button onClick={props.nextscreen}>Next</button>
                  </div>
                </div>

                </form>    
              </div>



            
            </div>
          </div>
    );
}

export default Weightndimension;