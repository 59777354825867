import React from 'react';
import Aux from '../../../HOC/Aux';

const Deliverybox = (props) => {

    return (
      <Aux>
       <div class="container-fluid pd-40" style={{height: "100%"}}>

<div class="d-flex justify-content-end">
{/*<!-- Rounded switch -->*/}
  <label class="switch">
    <input type="checkbox" onChange={props.changeview} checked/>
    <span class="slider round"></span>
  </label>
</div>



<div class="row ship-row">            
  <div class="col-md-6">
      <img src="assets/img/findyourperfect1.gif" class="img-fluid"/>
      <div class="loc-tags d-flex">
          <p>Kerala</p><p>Kottayam</p>
      </div>
  </div>
  <div class="col-md-6">
      <form class="ship-form">
        <div class="mb-3">
          <label for="exampleInputPackage" class="form-label">I Want to Ship a Package</label>
          <input type="text" class="form-control" id="exampleInputPackage" aria-describedby="package" placeholder="Package"/>
        </div>
        <div class="mb-3">
          <label for="inputState" class="form-label">Leaving In</label>
              <select id="inputState" class="form-select">
                <option selected="">Date</option>
                <option>...</option>
              </select>
        </div>
         <div class="mb-3">
          <label for="inputState" class="form-label">For</label>
              <select id="inputState" class="form-select">
                <option selected="">Any Duration</option>
                <option>...</option>
              </select>
        </div>
        <button type="submit" class="btn btn-primary form-button">Take me there</button>
      </form>
  </div>
  
</div>

</div> 

      </Aux>
        
    );
}

export default Deliverybox;