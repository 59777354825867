import React, { Component } from 'react';
import axios from 'axios';

class Test_fedex_booking extends Component  {


    componentDidMount() {
        console.log(" I am going to Test the Fedex Booking");

        let payload = {
            "wallet_balance": "",
            "courier_info": {
            "courier_name": "Fedex",
            "courier_mode": "FEDEX_EXPRESS_SAVER",
            "chargable_weight": 2
            },
            "pickup_details": {
            "pickup_company": "Ronsenterprises",
            "pickup_address1": "Adichira",
            "pickup_address2": "Thellakom P O",
            "pickup_zipcode": "686001",
            "pickup_place": "adichira",
            "pickup_state": "Kerala",
            "pickup_contact_person": "Jayalakshmi",
            "pickup_contact_number": "9946516960",
            "pickup_contact_email": "office@ronsenterprises.com"
            },
            "delivery_details": {
            "delivery_company": "Test Test Mumabi",
            "delivery_address1": "Sahar Airport Rd",
            "delivery_address2": "Ashok Nagar, Andheri East",
            "delivery_zipcode": "560010",
            "delivery_place": "Industrial Estate (Bangalore)",
            "delivery_state": "Karnataka",
            "delivery_contact_person": "Kanojia",
            "delivery_contact_number": "944791800",
            "delivery_contact_email": "kanojia@gmail.com"
            },
            "package_info": [
            {
                "weight": "2",
                "length": "10",
                "width": "10",
                "height": "15",
                "packagetype": "Package"
            }
            ],
            "insurance_details": {
                "goods_description": "taperolls",
                "invoice_value": "2000",
                "shipment_reference": "",
                "insurance": "YES"
            },
            "cost_details": {
            "transportation_cost": "140.4",
            "insurance_cost": "0",
            "total_cost": "140.4"
            },
            "update": true,
            "registered_email": "sales@ronsenterprises.com"
        }

        console.log("payload to send = "+JSON.stringify(payload));

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-booking',JSON.stringify(payload))
                .then(res => {
                    //let temp = res.config.data;
                    //let temp = JSON.parse(res.config.data);
                    ////console.log("In the result page = "+res.data);
                    console.log("In the result page = "+JSON.stringify(res.data));
                    /*
                    this.setState({
                        tracking: res.data.tracking_number,
                        pdfbase64 : res.data.pdf_base64

                    })
                    */
                   
                    
                    
                    
                })
                .catch(err => {
                    console.log("some error happened");
                });
    
    }

    render() {
        return(
            <div>

            </div>
        );
    }
}
export default Test_fedex_booking; 