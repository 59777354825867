import React, { Component } from 'react';
import {Route, Link, Switch} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify, { Auth,API } from 'aws-amplify';
import awsconfig from './aws-exports';
import Aux from './HOC/Aux';
import Recharge from './components/Modal/Recharge';
import Sidebar from './components/Sidebar/Sidebar';
import Mainpanel from './components/Wrapper/Mainpanel';
import NavigationBar from './components/Navbar/NavigationBar';
import Body from './components/Wrapper/Body';
import Recharge_modal from './components/Modal/Recharge_modal';
import Welcomepage from './components/Pages/Welcomepage/Welcomepage';
import Welcomepagedetails from './components/Pages/Welcomepage/Welcomepagedetails';
import Footer from './components/Footer/Footer';
import CompareRate from './components/Pages/CompareRates/CompareRate';
import Shipmentsummary from './components/Pages/Shipmentsummary/Shipmentsummary';
import Showtracking from './components/Pages/ShowTracking/Showtracking';
import Allshipmentpreview from './components/Pages/AllshipmentPreview/Allshipmentpreview';
import Testfunction from './components/Pages/Testfunction/Testfunction';
import Test_fedex_booking from './components/Pages/Testfunction/Test_fedex_booking';
import Secondview from './components/Pages/Welcomepage/Secondview';
import Courier_contacts from './components/Pages/Couriercontacts/Courier_contacts';
import Test_professional_rate from './components/Pages/Testfunction/Test_professional_rate';
import Test_fedex_heavy_rate from './components/Pages/Testfunction/Test_fedex_heavy_rate';
import Test_fedex_multiple_package from './components/Pages/Testfunction/Test_fedex_multiple_package';
import Test_printing from './components/Pages/Testfunction/Test_printing';
import Test_wallet_deduct from './components/Pages/Testfunction/Test_wallet_deduct';
import Test_pickup from './components/Pages/Testfunction/Test_pickup';

import * as actionTypes from './store/actions'
import Firstview from './components/Pages/Welcomepage/Firstview';
import ReversePickup from './components/Pages/Welcomepage/ReversePickup';
import Termsandcondition from './components/Footer/Termsandcondition';
import Privacy from './components/Footer/Privacy';
import Cancellation from './components/Footer/Cancellation';
import Shippingpolicy from './components/Footer/Shippingpolicy';
import Contactus from './components/Footer/Contactus';
import InternationalExport from './components/Pages/International/InternationalExport';
import InternationalImport from './components/Pages/International/InternationalImport';
import InternationalCrossExport from './components/Pages/International/InternationalCrossExport';


Amplify.configure(awsconfig);

class App extends Component {


  state = {
    balance : '00',
    RegisteredUser : '',
    hamburgerbuttonstatus : 'navbar-nav sidebar sidebar-light accordion'
  }


  /*
    paymenthandler = () => {

        alert("Got the click from Payment button");
    } 
*/


async fetchmybalance() {

  let payload = (await Auth.currentSession()).getIdToken();

  payload.operation = 'wallet_balance';

  
  //payload.update_amount ='300';

  console.log("IDtoken : "+JSON.stringify(payload));

  const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));

  console.log("response = "+JSON.stringify(server_response.data));

  let tempstate = this.state;

  tempstate.balance = server_response.data.Item.wallet_amount;
  tempstate.RegisteredUser = payload.payload.email;

  this.setState(tempstate);
  /*
  this.setState = (
    {
      balance : server_response.data.Item.wallet_amount 
    }
  )
*/
  console.log("state value = "+JSON.stringify(this.state))
  //this.setState ()

  
  this.props.updatewallet(server_response.data.Item.wallet_amount);

}


sidebartoggle_handler = () => {

  console.log("lets test if the click is responsing");

  if(this.state.hamburgerbuttonstatus === 'navbar-nav sidebar sidebar-light accordion')
  {
    this.setState({
      hamburgerbuttonstatus : 'navbar-nav sidebar sidebar-light accordion toggled'
    })

  }
  else {
    this.setState({
      hamburgerbuttonstatus : 'navbar-nav sidebar sidebar-light accordion'
    }) 
  }
    
}


 componentDidMount() {
    //Go to user account profile and fetch his current bank balance;

    //let querymessage = this.props.location.updatebalance;

    //console.log("Query mesgae = "+querymessage);

  this.fetchmybalance();


  let tempstate = this.state;
  tempstate.name ='romy';


  this.setState(tempstate)

  console.log("completed the com")

  }

  

  render() {

    console.log("i am in render = "+this.state.name);
    console.log("i am in render balance = "+this.state.balance);

    return (
      <Aux>
          
  
        
        {/*<Recharge 
        razor={(e) => this.paymenthandler()}
        />*/}
        <Recharge_modal/>

  {/*
        
        <div class="wrapper">
            <Sidebar/>

            <Mainpanel>
                
                <NavigationBar 
                
                currentbalance={this.props.walletamount}
                currentuser= {this.state.RegisteredUser}
                price={this.props.price}
                />
                <Switch>
                  <Route path="/" exact component={Secondview} />
                  <Route path="/secondview" exact component={Welcomepage} />
                  <Route path="/allratescompare" exact component={CompareRate} />
                  <Route path="/overview" exact component={Shipmentsummary} />
                  <Route path="/showtracking" exact component={Showtracking} />
                  <Route path="/allshipmentpreview" exact component={Allshipmentpreview}/>
                  <Route path="/fedexrates" exact component={Testfunction}/>
                  <Route path="/courier_contact" exact component={Courier_contacts}/>
                  <Route path="/test_fedexbooking" exact component={Test_fedex_booking}/>
                  <Route path="/test_professional_rate" exact component={Test_professional_rate}/>
                  <Route path="/test_fedex_heavy_rate" exact component={Test_fedex_heavy_rate}/>
                  <Route path="/test_fedex_book_multiple" exact component={Test_fedex_multiple_package}/>
                  <Route path="/test_printing" exact component={Test_printing}/>
                  <Route path="/test_wallet" exact component={Test_wallet_deduct}/>
                  <Route path="/test_pickup" exact component={Test_pickup}/>
                </Switch>
                <Footer/>
            </Mainpanel>
        
        </div>
  */}      


<section id="wrapper">
    <Sidebar 
      burgerclick={this.state.hamburgerbuttonstatus}
    />
    <div id="content-wrapper" class="d-flex flex-column">
      <NavigationBar 
        currentbalance={this.props.walletamount}
        currentuser= {this.state.RegisteredUser}
        hamburgerclick = {() => this.sidebartoggle_handler()}
      /> 
      <Switch>
        <Route path="/" exact component={Firstview} />
        <Route path="/secondview" exact component={Welcomepage} />
        <Route path="/reversepickup" exact component={ReversePickup} />
        <Route path="/allratescompare" exact component={CompareRate} />
        <Route path="/overview" exact component={Shipmentsummary} />
        <Route path="/showtracking" exact component={Showtracking} />
        <Route path="/allshipmentpreview" exact component={Allshipmentpreview}/>
        <Route path="/courier_contact" exact component={Courier_contacts}/>
        <Route path="/test_pickup" exact component={Test_pickup}/>
        <Route path="/international_export" exact component={InternationalExport}/>
        <Route path="/international_import" exact component={InternationalImport}/>
        <Route path="/international_cross" exact component={InternationalCrossExport}/>
        <Route path="/termsandcondition" exact component={Termsandcondition}/>
        <Route path="/privacy" exact component={Privacy}/>
        <Route path="/cancellation" exact component={Cancellation}/>
        <Route path="/shippingpolicy" exact component={Shippingpolicy}/>
        <Route path="/contactus" exact component={Contactus}/>
        <Route path="/test_fedexbooking" exact component={Test_fedex_booking}/>
      </Switch>
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
</section>

      </Aux>
      
    );
  }
  
}

const mapStateToprops = state => {
  return {
      price : state.totalprice,
      walletamount : state.WalletBalance,
      wallet : state.totalprice
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updatewallet : (amount) => dispatch({ type: actionTypes.UPDATE_WALLET,payload:amount} )
  }
}

export default withAuthenticator(connect(mapStateToprops,mapDispatchToProps)(App));
