import React from 'react';
import Aux from '../../../HOC/Aux';

const Contactcard = (props) => {

    return (
        <Aux>
            <div class="col-md-5 col-lg-4 offset-lg-2 card card1 card2 tp-bd">
                                    <div class="pre-border">
                                    </div>
                                    <img src={props.Logo} class="img-fluid success-img"/>
                                    <div class="row">
                                        <div class="col-md-4 p-0">
                                        <p><span><i class="pe-7s-user"></i></span>Name</p>
                                        </div>
                                        <div class="col-md-8 card-input">
                                        <p>{props.Name}</p>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4 p-0">
                                        <p><span><i class="pe-7s-science" aria-hidden="true"></i></span>Email</p>
                                        </div>
                                        <div class="col-md-8 card-input">
                                        <p>{props.Email}</p>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4 p-0">
                                        <p><span><i class="pe-7s-user"></i></span>Mobile</p>
                                        </div>
                                        <div class="col-md-8 card-input">
                                        <a href="tel:{props.Mobile}">{props.Mobile}</a>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4 p-0">
                                        <p><span><i class="pe-7s-science" aria-hidden="true"></i></span>Whatsapp</p>
                                        </div>
                                        <div class="col-md-8 card-input">
                                        <p>{props.Whatsapp}</p>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4 p-0">
                                        <p><span><i class="pe-7s-science" aria-hidden="true"></i></span>Addrress</p>
                                        </div>
                                        <div class="col-md-8 card-input">
                                        <p>{props.Address}</p>
                                        </div>
                                    
                                    </div>
                                </div>
        </Aux>
    );
}

export default Contactcard;