import React, { Component } from 'react';
import Contact_card from '../ShowTracking/Contactcard';
import Amplify, { Auth,API } from 'aws-amplify';
import axios from 'axios';
import Aux from '../../../HOC/Aux';
import { Row } from 'react-bootstrap';

class Courier_contacts extends Component {

    state = {
        tracking: "waiting....",
        pdfbase64 : 'base64',
        Courier_company : '',
        Courier_contact_personname : '',
        Courier_contact_primary_number : '',
        Courier_contact_number_2 : '',
        Courier_contact_whatsapp : '',
        Courier_contact_email : '',
        Courier_contact_address : '',
        Courier_info : [],
        Courier_updated : []
    }

    async fetchmybalance() {

        let payload = (await Auth.currentSession()).getIdToken();
      
        payload.operation = 'wallet_balance';
        //payload.update_amount ='300';
      
        //console.log("IDtoken : "+JSON.stringify(payload));
      
        const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));
      
        console.log("response = "+JSON.stringify(server_response.data.Item));

        let courier_array = server_response.data.Item.Courier_contactinfo;

        /*
        this.setState({
            ...this.state,
            Courier_info : courier_array
        }
        )
        */

       let courier_loop = courier_array; 
       let temp_array = [];

        console.log("I am rendinging now");

        for (let index = 0; index <= courier_loop.length; index++) {

            let splice_array;
            console.log("courier loop length ="+courier_loop.length);

            /*
            if(courier_loop.length >= 3) {

                splice_array = courier_loop.splice(0,3);
            }
            else if(courier_loop.length >= 2) {

                splice_array = courier_loop.splice(0,2);
            }
            else {

                splice_array = courier_loop.splice(0,1);
            }
            */

           // if you want to display 3 courier contact card then splice the arrary in (0,3) 
            splice_array = courier_loop.splice(0,2);
            console.log("courier_loop spliced = "+JSON.stringify(courier_loop));
            console.log("courier spliced = "+JSON.stringify(splice_array));
/*
            let data_splice = {
                courier_1 : '',
                contact_name_1 : '',
                courier_2 : '',
                contact_name_2 : '',
                courier_3 : '',
                contact_name_3 : ''
            }
 */

            let data_splice = {};
            for (let index = 0; index < splice_array.length; index++) {

                
                
                if(index == 0) {
                    console.log("insode loop courier ="+splice_array[index].Courier);
                    data_splice.courier_1 = splice_array[index].Courier;
                    data_splice.contact_name_1 = splice_array[index].Contact_name; 
                    data_splice.contact_number_1 = splice_array[index].Contact_number; 
                    data_splice.logo_1 = splice_array[index].Logo; 
                    data_splice.address_1 = splice_array[index].Address; 
                }
                if(index == 1) {
                    console.log("insode loop courier ="+splice_array[index].Courier);
                    data_splice.courier_2 = splice_array[index].Courier;
                    data_splice.contact_name_2 = splice_array[index].Contact_name; 
                    data_splice.contact_number_2 = splice_array[index].Contact_number; 
                    data_splice.logo_2 = splice_array[index].Logo; 
                    data_splice.address_2 = splice_array[index].Address; 
                }
                if(index == 2) {
                    console.log("insode loop courier ="+splice_array[index].Courier);
                    data_splice.courier_3 = splice_array[index].Courier;
                    data_splice.contact_name_3 = splice_array[index].Contact_name; 
                    data_splice.contact_number_3 = splice_array[index].Contact_number; 
                    data_splice.logo_3 = splice_array[index].Logo; 
                    data_splice.address_3 = splice_array[index].Address; 
                }

                console.log("Data Splice details ="+ JSON.stringify(data_splice));
                
            }

            temp_array.push(data_splice);

        }

        this.setState({
            ...this.state,
            Courier_info : temp_array
        }
        )
      
      }

    componentDidMount(){
        this.fetchmybalance();
    }

    render() {

        let courier_loop = [...this.state.Courier_info];

        

        return(
            <Aux>

                <div class="container-fluid pd-40 mb-5">
                <div class="card card2 mb-5 pt-4 pb-4 pb-19">
                
                    {courier_loop.map((row) => {

                        let active ='';
                        
                            return(
                                <div>
                                    <div class="row d-flex justify-content-center">
                                
                                    {(row.courier_1) ? <Contact_card Name={row.contact_name_1}
                                                                        Mobile = {row.contact_number_1}
                                                                        Logo = {row.logo_1}
                                                                        Address = {row.address_1}/> : ''}
                                    
                                    {(row.courier_2) ? <Contact_card Name={row.contact_name_2}
                                                                        Mobile = {row.contact_number_2}
                                                                        Logo = {row.logo_2}
                                                                        Address = {row.address_2}/> : ''}
                                    {(row.courier_3) ? <Contact_card/> : ''}
                                    </div>
                                    
                                </div>    
                            ) 
                        

                            
                    })}

                    
                </div>            
                </div>            
                           
                        
        </Aux>
        );

    }

    
}

export default Courier_contacts;


{/*<div class="row">
                                <Contact_card
                           Name = {this.state.Courier_contact_personname}
                           Number = {this.state.Courier_contact_primary_number}
                           Email = {this.state.Courier_contact_email}
                           Whatsapp = {this.state.Courier_contact_whatsapp}
                           Mobile = {this.state.Courier_contact_primary_number}
                           Address = {this.state.Courier_contact_address}
                           /> 
                           <Contact_card/>
                           <Contact_card/>
                        </div>*/}