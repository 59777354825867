import React, { Component } from 'react'
import Aux from '../../../HOC/Aux';
import Dimensionbox from './Dimensionbox';
import axios from 'axios';
import Amplify, { Auth,API } from 'aws-amplify';
import { connect } from 'react-redux';

import * as actionTypes from '../../../store/actions'


class ReversePickup extends Component  {


    state = {
        //package_array : [''],
        weight_display : '00',
        width_display : '00',
        lenght_display : '00',
        height_display : '00',
        packageimage : 'assets/img/pack-envelope.png',
        deliveryplace: '',
        deliverydistrict:'',
        deliverystate: '',
        deliveryzip : 'Wrong Zip Code',
        pickupzip:'Enter the Zip Code',
        pickupplace : '',
        pickupstate:'',
        package_count : '0',
        packageinfo: [
          {
            weight:'00',
            length:'00',
            width:'00',
            height:'00',
            packagetype:'Package' 
          }
        ]
      }

      async fetchpickupdetails () {

        let payload = (await Auth.currentSession()).getIdToken();
        payload.operation = 'wallet_balance';
    
        const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));
    
        console.log("response fetch secondview= "+JSON.stringify(server_response.data));
    
       this.setState(
         {
           ...this.state,
           //pickupzip:server_response.data.Item.Default_address.zipcode,
           //pickupplace:server_response.data.Item.Default_address.place, 
           //pickupstate:server_response.data.Item.Default_address.state,
         }
       )
    
      }

      dimensionhandler (event,type,index_number) {

        console.log("Details entered is ="+ type)
        console.log("Value entered is ="+ event.target.value)
        console.log("dimension enter for position"+index_number);
    
    
        if(type ==='packagetype'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].weight = event.target.value;
          temparray[index_number].packagetype = event.target.value;
    
          this.setState({
            ...this.state,
            packageinfo : temparray
          })
        }
    
        if(type ==='weight'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].weight = event.target.value;
          temparray[index_number].weight = event.target.value;
    
          this.setState({
            ...this.state,
            weight_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='width'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].width = event.target.value;
          temparray[index_number].width = event.target.value;
    
          this.setState({
            ...this.state,
            width_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='lenght'){
    
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].length = event.target.value;
          temparray[index_number].length = event.target.value;
    
          this.setState({
            ...this.state,
            lenght_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='height'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].height = event.target.value;
          temparray[index_number].height = event.target.value;
    
          this.setState({
            ...this.state,
            height_display : event.target.value,
            packageinfo : temparray
          })
        }
    
      }

    fetchpickupzipcode = (event) => {

        let zip = event.target.value;

        console.log("Getting the pickup pin ="+zip)
        
    
        axios.get('https://api.postalpincode.in/pincode/'+zip)
                .then(res => {
                    console.log(res.data[0].PostOffice[0].State);
                    const tempstate = this.state;
                    tempstate.pickupstate = (res.data[0].PostOffice[0].State);
                    tempstate.pickupplace = (res.data[0].PostOffice[0].Name);
                    tempstate.pickupzip = (res.data[0].PostOffice[0].Pincode);
                    //tempstate.deliverydistrict = (res.data[0].PostOffice[0].District);
                    //tempstate.deliveryzip = (res.data[0].PostOffice[0].Pincode);

                    let pickupinfo = {
                            zipcode : res.data[0].PostOffice[0].Pincode,
                            place : tempstate.pickupplace,
                            state : tempstate.pickupstate 
                    }

                    this.props.updatereversepickupzip(pickupinfo);                    

                    this.setState({
                            tempstate
                    });
    
                })
                .catch(error => {
    
                    const tempstate = this.state;
                    tempstate.deliveryplace = "Wrong Zip Code"
                    tempstate.deliverystate = "Wrong Zip Code"
                    tempstate.deliveryzip = "Wrong Zip Code"
                    tempstate.deliverydistrict = "Wrong Zip Code"
                    this.setState({
                        tempstate
                }); 
                    console.log("some error in fetch zip happened")
    
                }); 
    }

    fetchzipcode = (event) => {

        let zip = event.target.value;
    
        axios.get('https://api.postalpincode.in/pincode/'+zip)
                .then(res => {
                    console.log(res.data[0].PostOffice[0].State);
                    const tempstate = this.state;
                    tempstate.deliverystate = (res.data[0].PostOffice[0].State);
                    tempstate.deliveryplace = (res.data[0].PostOffice[0].Name);
                    tempstate.deliverydistrict = (res.data[0].PostOffice[0].District);
                    tempstate.deliveryzip = (res.data[0].PostOffice[0].Pincode);
                    this.setState({
                            tempstate
                    });
    
                })
                .catch(error => {
    
                    const tempstate = this.state;
                    tempstate.deliveryplace = "Wrong Zip Code"
                    tempstate.deliverystate = "Wrong Zip Code"
                    tempstate.deliveryzip = "Wrong Zip Code"
                    tempstate.deliverydistrict = "Wrong Zip Code"
                    this.setState({
                        tempstate
                }); 
                    console.log("some error in fetch zip happened")
    
                });
    
    }

    addpackagehandler = () => {
        console.log("got the click on add package")
        //let package_array = [...this.state.package_array];
        let package_info_array = [...this.state.packageinfo];
        let state_copy = this.state;
    
    
        //state_copy.package_array = package_array;
        state_copy.packageinfo = package_info_array;
    
    
        //package_array.push('');
        package_info_array.push({
          weight:'5',
          length:'15',
          width:'15',
          height:'15',
          packagetype:'Package' 
        })
    
        this.setState({
          state_copy
        }); 
      }
    

    changeviewhandler = () => {

        this.props.history.push({
          pathname: '/secondview'
      });
        
    
    }

    deletepackagehandler = (index_number) => {
        //let package_array = [...this.state.package_array]
        let package_info_array = [...this.state.packageinfo];
        let state_copy = this.state;
        
    
        console.log("got the click "+package_info_array.length);
        if(package_info_array.length !== 1) {
          console.log("asking to delete the package index no = "+index_number);
          //package_array.splice(index_number,1);
          package_info_array.splice(index_number,1);
          //state_copy.package_array = package_array;
          state_copy.packageinfo = package_info_array;
          this.setState({
            state_copy
          });
    
        }
      }

      fetchratehandler = () => {

        if(this.state.deliveryzip === 'Wrong Zip Code') {
    
          alert("Please cross check the PINCODE and correct it");
        }
        else {
    
        //console.log("I am going to fetch the rates"+index);
        console.log (this.props);
    
        const stateduplicate = {}
    
        stateduplicate.deliveryplace = this.state.deliveryplace;
        stateduplicate.deliverydistrict = this.state.deliverydistrict;
        stateduplicate.deliverystate = this.state.deliverystate;
        stateduplicate.deliveryzip = this.state.deliveryzip
        stateduplicate.pickupzip = this.state.pickupzip;
        stateduplicate.pickupstate = this.state.pickupstate;
        stateduplicate.pickupplace = this.state.pickupplace;
        stateduplicate.packageinfo = [...this.state.packageinfo];
    
        this.props.history.push({
            pathname: '/allratescompare',
            querydata : stateduplicate
        });
    
        }
    
    }

    componentDidMount() {

        //fetch the pickup address from the user profile 
        this.fetchpickupdetails();
        
        //activating the reversepickup in central store 
        this.props.updatereversepickup();
      
      }

    render() {

        let package_array = this.state.packageinfo

        return(
            <Aux>
                <div class="container-fluid pd-40">
          <div class="row">            
            {/*<p>Enter the Delivery Zip Code</p>*/}
            <div class="d-flex justify-content-end">
          
            <label class="switch">
              <input type="checkbox" onChange={this.changeviewhandler}/>
              <span class="slider round"></span>
            </label>
          </div>  
          <div class="col-md-6 form-sec">
            
            <div class="card">
              <div class="container">
              <p>Pickup Details</p>
              <form>
                <label>Country</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
            <input type="text" class="form-control" placeholder="INDIA" aria-describedby="sizing-addon2" readonly=""/>
            </div>

            <label>Zipcode</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="text" class="form-control" placeholder="Enter the Pick up Zip Code" aria-describedby="sizing-addon2" maxLength="6" onChange={this.fetchpickupzipcode}/>
            </div>

             <label>Place</label>
             <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-paper-plane"></i></span>
            <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={this.state.pickupplace} readOnly/>
            
            </div>

            <label>State</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
            <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={this.state.pickupstate} readOnly/>
            
            </div></form>

            </div></div>
          </div>


           <div class="col-md-6 form-sec">
            
            <div class="card">
              <div class="container">
              <p>Delivery Details</p>
              <form>
                <label>Country</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
            <input type="text" class="form-control" placeholder="INDIA" aria-describedby="sizing-addon2" readonly=""/>
            </div>

            <label>Zipcode</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            {/*<input type="text" class="form-control" placeholder="Enter the delivery pin code" aria-describedby="sizing-addon2"/>*/}
            <input type="tel" class="form-control" placeholder="Enter the Delivery Pin Code" aria-describedby="sizing-addon2" 
                    maxLength="6" onChange={this.fetchzipcode} />
            </div>

             <label>Place</label>
             <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-paper-plane"></i></span>
            <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2"
                value={this.state.deliveryplace} readOnly
            />
            
            </div>

            <label>State</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
            <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2"
                   value={this.state.deliverystate} readOnly/>
            
            </div></form>

            </div></div>
          </div>


          <div class="col-md-12 form-sec">
            <p>Enter the package dimensions and weight to calculate the delivery rate</p>
            {package_array.map((row,index) => {
                return(
                    <Dimensionbox
                    key={index}
                    dimension_handler = {(e,type) => this.dimensionhandler(e,type,index)}
                    delete_package = {(e)=> this.deletepackagehandler(index)} />
                );
            })}
          
            <div class="btn-footer d-flex align-items-center flex-column flex-md-row justify-content-center">
              <button type="submit" class="btn btn-default login-btn" onClick={this.addpackagehandler} >Add Additional Package &nbsp;<i class="pe-7s-plus"></i></button>

               <button type="submit" class="btn btn-default login-btn calc-btn mt-3 mt-md-0" onClick={this.fetchratehandler}>Calculate</button>
              
            </div>
          

          </div>
            
        </div>
        
      </div>

            </Aux>
        )
    }

}

const mapStateToprops = state => {
    return {
        reversepickup : state.reversedelivery,
        reversepickupzip : state.reversepickupzip,
        reversepickupplace :state.reversePickupPlace,
        reversepickupstate : state.reversePickupState,
        walletamount : state.WalletBalance,
        wallet : state.totalprice
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        updatewallet : (amount) => dispatch({ type: actionTypes.UPDATE_WALLET,payload:amount} ),
        updatereversepickupzip : (zipcode) => dispatch({type : actionTypes.UPDATE_REVERSE_ZIP,payload:zipcode}),
        updatereversepickup : () => dispatch({type : actionTypes.UPDATE_REVERSEPICKUP,payload : 'true'})
    }
  }

export default connect(mapStateToprops,mapDispatchToProps)(ReversePickup);