import React from 'react';
import Aux from '../../../HOC/Aux';

const Previewbody = (props) => {



    return (
      <Aux>
        <div class="col-md-6 col-lg-12 ">
                <div class="row table-data-row mt-3">
                    <div class="col-lg col-6">
                        <div>
                            <h3>Shipment Id</h3>
                            <p>{props.shipmentid}</p>
                        </div>
                    </div>
                    
                    <div class="col-lg col-6 mt-4 mt-lg-0">
                        <div>
                            <h3>Courier</h3>
                            <p>{props.courier}</p>
                        </div>
                    </div>
                    <div class="col-lg col-6">
                        <div>
                            <h3>Trackment No</h3>
                            <p>{props.trackingnumber}</p>
                        </div>
                    </div>
                    <div class="col-lg col-6 mt-4 mt-lg-0">
                        <div>
                            <h3>Mode</h3>
                            <p>{props.mode}</p>
                        </div>
                    </div>
                    <div class="col-lg col-6 mt-4 mt-lg-0">
                        <div>
                            <h3>Customer Name</h3>
                            <p>{props.customername}</p>
                        </div>
                    </div>
                    <div class="col-lg col-6 mt-4 mt-lg-0">
                        <div>
                            <h3>Booking Date</h3>
                            <p>{props.bookdate}</p>
                        </div>
                    </div>
                    <div class="col-lg col-6 mt-4 mt-lg-0">
                        <div>
                            <h3>Rate</h3>
                            <p class="courier-status">INR {props.price}</p>
                        </div>
                    </div>
                    <div class="col-lg col-12 mt-4 mt-lg-0">
                        <button type="submit" class="btn btn-primary data-table-btn" onClick={() => props.link(props.courier)}>TRACK</button>
                        <button type="submit" class="btn btn-primary data-table-btn" onClick={() => props.print(props.trackingnumber)}>COPY</button>
                    </div>
                </div>
            </div>

      </Aux>
        
    );
}

export default Previewbody;


{/*<div class="card col-sm-6">
        <div class="container">
          <div class="Courier-table">

            <div class="col-md-2 col-xs-6">
              <h3>Shipment Id</h3>
               <p>{props.shipmentid}</p>
            </div>

            <div class="col-md-2 col-xs-6">
              <h3>Courier</h3>
              <p>{props.courier}</p>
            </div>

            <div class="col-md-2 col-xs-6">
              <h3>Trackment No</h3>
              <p>{props.trackingnumber}</p>
            </div>

            <div class="col-md-2 col-xs-6">
              <h3>Mode</h3>
              <p>{props.mode}</p>
            </div>

            <div class="col-md-2 col-xs-6">
              <h3>Customer Name</h3>
              <p>{props.customername}</p>
            </div>

            <div class="col-md-2 col-xs-6">
              <h3>Booking Date</h3>
              <p>{props.bookdate}</p>
            </div>

            <div class="col-md-1 col-xs-12">
              <h3>Status</h3>
              <p><span>{props.status}Shipped</span></p>
            </div>

            <div class="col-md-1 col-xs-12">
              
              <button onClick={() => props.link(props.courier)}>Track</button>
            </div>

          </div>
        </div>
    </div>*/}