import React, { Component } from 'react';
import Deliverybox from './Deliverybox';
import Amplify, { Auth,API } from 'aws-amplify';
import Aux from '../../../HOC/Aux';
import axios from 'axios';
import Selectpackage from './Selectpackage';
import Welcomepagedetails from './Welcomepagedetails';
import Weightndimension from './Weightndimension';
import Widthndimension from './Widthndimension';
import Add_or_check from './Add_or_check';
import Secondview from './Secondview';
import Thirdview from './Thirdview';

class Welcomepage extends Component  {

  state = {
    flag : '00',
    weight_display : '00',
    width_display : '00',
    lenght_display : '00',
    height_display : '00',
    packageimage : 'assets/img/pack-envelope.png',
    deliveryplace: '',
    deliverydistrict:'',
    deliverystate: '',
    deliveryzip : 'Wrong Zip Code',
    pickupzip:'',
    pickupplace : 'Kottayam',
    pickupstate:'Kerala',
    package_count : '0',
    packageinfo: [
      {
        weight:'55',
        length:'15',
        width:'20',
        height:'25',
        packagetype:'Package' 
      }
    ]
  }


  fetchratehandler = (index) => {

    if(this.state.deliveryzip === 'Wrong Zip Code') {

      alert("Please cross check the PINCODE and correct it");
    }
    else {

      console.log("I am going to fetch the rates"+index);
    console.log (this.props);

    const stateduplicate = {}

    stateduplicate.deliveryplace = this.state.deliveryplace;
    stateduplicate.deliverydistrict = this.state.deliverydistrict;
    stateduplicate.deliverystate = this.state.deliverystate;
    stateduplicate.deliveryzip = this.state.deliveryzip
    stateduplicate.pickupzip = this.state.pickupzip;
    stateduplicate.pickupstate = this.state.pickupstate;
    stateduplicate.pickupplace = this.state.pickupplace;
    stateduplicate.packageinfo = [...this.state.packageinfo];

    this.props.history.push({
        pathname: '/allratescompare',
        querydata : stateduplicate
    });

    }

    
    

}

  fetchzipcode = (event) => {

    let zip = event.target.value;

    axios.get('https://api.postalpincode.in/pincode/'+zip)
            .then(res => {
                console.log(res.data[0].PostOffice[0].State);
                const tempstate = this.state;
                tempstate.deliverystate = (res.data[0].PostOffice[0].State);
                tempstate.deliveryplace = (res.data[0].PostOffice[0].Name);
                tempstate.deliverydistrict = (res.data[0].PostOffice[0].District);
                tempstate.deliveryzip = (res.data[0].PostOffice[0].Pincode);
                this.setState({
                        tempstate
                });

/*
                this.props.history.push({
                    pathname: '/allrates',
                    state: {
                        pickupzip:this.state.pickupzip,
                        pickupstate:this.state.pickupstate,
                        deliveryzip:newchange.zipcode,
                        deliverystate:tempstate.deliverystate,
                        packageweight:'1.2',
                        packagelenght:'15',
                        packagewidth:'20',
                        packageheight:'25'
                    }
                });
*/

            })
            .catch(error => {

                const tempstate = this.state;
                tempstate.deliveryplace = "Wrong Zip Code"
                tempstate.deliverystate = "Wrong Zip Code"
                tempstate.deliveryzip = "Wrong Zip Code"
                tempstate.deliverydistrict = "Wrong Zip Code"
                this.setState({
                    tempstate
            }); 
                console.log("some error in fetch zip happened")

            });

  }

  screenselectionhandler () {

      if(this.state.flag === '00'){
        this.setState(
          {
            ...this.state,
            flag : '01'
          }
        )
      }

      if(this.state.flag === '01'){
        this.setState(
          {
            ...this.state,
            flag : '02'
          }
        )
      }

      if(this.state.flag === '02'){
        this.setState(
          {
            ...this.state,
            flag : '06'
          }
        )
      }

      if(this.state.flag === '03'){
        this.setState(
          {
            ...this.state,
            flag : '04'
          }
        )
      }

      if(this.state.flag === '04'){
        this.setState(
          {
            ...this.state,
            flag : '05'
          }
        )
      }

      if(this.state.flag === '05'){
        this.setState(
          {
            ...this.state,
            flag : '06'
          }
        )
      }

  }

  async fetchpickupdetails () {

    let payload = (await Auth.currentSession()).getIdToken();
    payload.operation = 'wallet_balance';

    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));

    console.log("response = "+JSON.stringify(server_response.data));

   this.setState(
     {
       ...this.state,
       pickupzip:server_response.data.Item.Default_address.zipcode,
       pickupplace:server_response.data.Item.Default_address.place, 
       pickupstate:server_response.data.Item.Default_address.state,
     }
   )

  }

  packagetypehandler = (event) => {

    console.log("package selected ="+event.target.value)

    let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      temparray[packcount].packagetype = event.target.value;

    let packageimage = event.target.value;

    if(packageimage === 'Document'){
      this.setState(
        {
          ...this.state,
          packageimage : 'assets/img/envelope.png',
          packageinfo : temparray
        }
      )
    }

    if(packageimage === 'Package'){

      this.setState(
        {
          ...this.state,
          packageimage : 'assets/img/package.png',
          packageinfo : temparray
        }
      )
    }

    if(packageimage === 'pallet'){

      this.setState(
        {
          ...this.state,
          packageimage : 'assets/img/pallet.png',
          packageinfo : temparray
        }
      )
    }

  }

  dimensionhandler (event,type) {

    console.log("Details entered is ="+ type)
    console.log("Value entered is ="+ event.target.value)

    if(type ==='weight'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      temparray[packcount].weight = event.target.value;

      this.setState({
        ...this.state,
        weight_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='width'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      temparray[packcount].width = event.target.value;

      this.setState({
        ...this.state,
        width_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='lenght'){


      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      temparray[packcount].length = event.target.value;

      this.setState({
        ...this.state,
        lenght_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='height'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      temparray[packcount].height = event.target.value;

      this.setState({
        ...this.state,
        height_display : event.target.value,
        packageinfo : temparray
      })
    }

  }


  additionalpackagehandler() {

    let newcount = this.state.package_count;

    newcount = parseInt(newcount)+1;

    let temparray = [...this.state.packageinfo];

    let temp_object = {
      weight:'55',
        length:'15',
        width:'20',
        height:'25',
        packagetype:'Courier'
    }

    temparray.push(temp_object);

    this.setState({
      ...this.state,
      flag : '01',
      height_display : '00',
      width_display : '00',
      lenght_display : '00',
      weight_display : '00',
      package_count : newcount,
      packageinfo : temparray
    })
  }
 
  changeviewhandler = () => {

    /*
    if(this.state.flag === '07') {
      this.setState({
        ...this.state,
        flag : '00'
      })
    }
    else {
      this.setState({
        ...this.state,
        flag : '07'
      }) 
    }
   */
    this.props.history.push({
      pathname: '/'
  });
    

  }

 componentDidMount() {

    //fetch the pickup address from the user profile 
    this.fetchpickupdetails();

  
  }

  render () {

    let flag = this.state.flag;
    let current_screen;

    if(flag === '00') {
      current_screen = <Deliverybox
      delivery_zip = {this.state.deliveryzip}
      //zipvalid = {((this.state.deliveryzip)=='Wrong Zip Code') ? "Wrong Zip Code" : "NEXT"}
      state = {this.state.deliverystate}
      place = {this.state.deliveryplace}
      zipvalid = {this.state.deliveryzip}
      getzip_delivery = {(e) => this.fetchzipcode(e)}
      changeview = {(e) => this.changeviewhandler()}
      calculate = {(e) => this.screenselectionhandler()}
    /> 
  
    }

    if(flag ==='01') {

      current_screen = <Selectpackage
      packageimage = {this.state.packageimage}
      packagetype = {(e) => this.packagetypehandler(e)}
      nextscreen = {(e) => this.screenselectionhandler()} 
      />
    }

    if(flag === '02'){
      current_screen = <Thirdview
      weight = {this.state.weight_display}
      width = {this.state.width_display}
      length = {this.state.lenght_display}
      height = {this.state.height_display}
      type = "weight"
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)}
      nextscreen = {(e) => this.screenselectionhandler()} 
      />


      {/*current_screen = <Weightndimension
      weight = {this.state.weight_display}
      width = {this.state.width_display}
      length = {this.state.lenght_display}
      height = {this.state.height_display}
      type = "weight"
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)} 
      nextscreen = {(e) => this.screenselectionhandler()} 
      />*/}    
    }

    if(flag === '03'){
      current_screen = <Widthndimension
      weight = {this.state.weight_display}
      width = {this.state.width_display}
      length = {this.state.lenght_display}
      height = {this.state.height_display}
      type = "width"
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)} 
      nextscreen = {(e) => this.screenselectionhandler()} 
      />    
    }

    if(flag ==='04'){
      current_screen = <Weightndimension
      weight = {this.state.weight_display}
      width = {this.state.width_display}
      length = {this.state.lenght_display}
      height = {this.state.height_display}
      type = "lenght"
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)} 
      nextscreen = {(e) => this.screenselectionhandler()} 
      /> 
    }

    if(flag ==='05'){
      current_screen = <Widthndimension
      weight = {this.state.weight_display}
      width = {this.state.width_display}
      length = {this.state.lenght_display}
      height = {this.state.height_display}
      type = "height"
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)} 
      nextscreen = {(e) => this.screenselectionhandler()} 
      /> 
    }

    if(flag ==='06') {
      current_screen = <Add_or_check
      additional_package = {(e)=> this.additionalpackagehandler()}
      calculate = {(e)=> this.fetchratehandler()} 
      />
    }

    if(flag ==='07') {
      current_screen = <Secondview
      changeview = {(e) => this.changeviewhandler()}
      pickupzip = {this.state.pickupzip}
      state = {this.state.deliverystate}
      place = {this.state.deliveryplace}
      delivery_zip = {this.state.deliveryzip}
      getzip_delivery = {(e) => this.fetchzipcode(e)} 
      dimension_handler = {(e,type) => this.dimensionhandler(e,type)}
      packagetype = {(e) => this.packagetypehandler(e)}
      additional_package = {(e)=> this.additionalpackagehandler()}
      calculate = {(e)=> this.fetchratehandler()} 
      />
    }

    return(
      <Aux>
        {current_screen}
      </Aux>
      
    );
  }
    
}

export default Welcomepage;