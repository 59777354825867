import React from 'react';
import {Route, Link} from 'react-router-dom';
import Aux from '../../HOC/Aux';

const Sidebar = (props) => {

    return (
        
        <Aux>
            {/*<!-- Sidebar -->*/}
    <ul class={props.burgerclick} id="accordionSidebar">
    <a class="sidebar-brand d-flex align-items-center justify-content-center">
      <div class="sidebar-brand-icon">
        <img src="/Bootstrap5_assets/img/logo-white.png"/>
      </div>
    </a>
   
    <li class="nav-item">
        <Link to="/" class="nav-link">
            <i class="pe-7s-graph"></i>
            <span>Dashboard</span>
        </Link>
    </li>
   
    <li class="nav-item">
      <Link to="/allshipmentpreview" class="nav-link">
        <i class="pe-7s-science"></i>
        <span>All Shipment Preview</span>
      </Link>
    </li>

    <li class="nav-item">
      <Link to="/reversepickup" class="nav-link">
        <i class="pe-7s-way"></i>
        <span>Reverse Pickup</span>
      </Link>
    </li>
    
    <li class="nav-item">
        <Link to="/courier_contact" class="nav-link">
            <i class="pe-7s-map-marker"></i>
            <span>View All Coutier contact</span>
        </Link>
    </li>
    
    <li class="nav-item">
      <Link to="/test_fedexbooking" class="nav-link">
          <i class="pe-7s-map-marker"></i>
          <span>Fedex Booking Test</span>
      </Link>
    </li>

    <li class="nav-item">
        <Link to="/test_pickup" class="nav-link">
            <i class="pe-7s-hourglass"></i>
            <span>Fedex Pickup Test</span>
        </Link>
    </li>
 
    {/*<li class="nav-item">
      <a class="nav-link" href="#">
        <i class="pe-7s-map-marker"></i>
        <span>Wallet Deduct Test</span>
      </a>
    </li>*/}

    <li class="nav-item">
      <Link to="/international_export" class="nav-link">
        <i class="pe-7s-plane"></i>
        <span>International Export</span>
      </Link>
    </li>

    <li class="nav-item">
      <Link to="/international_import" class="nav-link">
        <i class="pe-7s-helm"></i>
        <span>International Import</span>
      </Link>
    </li>

    <li class="nav-item">
      <Link to="/international_cross" class="nav-link">
        <i class="pe-7s-arc"></i>
        <span>CROSS Country Export</span>
      </Link>
    </li>

    
  </ul>
{/*<!-- Sidebar Ends-->*/}
    </Aux>
    )

}

export default Sidebar;