import React from 'react';
import {Route, Link} from 'react-router-dom';

const Footer = (props) => {

    return (
        <div>
             <footer>
                 
      	<div class="container-fluid d-flex justify-content-between flex-column flex-md-row">
      	<ul>
          
              <li><Link to="/" class="nav-link">Home</Link></li>
              <li><Link to="/termsandcondition" class="nav-link">Terms & Conditions</Link></li>
              <li><Link to="/privacy" class="nav-link">Privacy</Link></li>
              <li><Link to="/cancellation" class="nav-link">Cancellation & Return</Link></li>
              <li><Link to="/shippingpolicy" class="nav-link">Policy</Link></li>
              <li><Link to="/contactus" class="nav-link">Contact Us</Link></li>
          
      	</ul>
          <p class="copyright pull-right">©2020 freightmeter.in</p> 
      </div>
      </footer>
        </div>
       
      
    );

}

export default Footer;