import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

class Test_wallet_deduct extends Component  {

   async componentDidMount(){

        let payload = (await Auth.currentSession()).getIdToken();
        //const payload = {
            //        operation : 'update_wallet',
            //        update_amount : amount_fromrazor
         //   }
    
        // amount to be updated in the waller of the dynammo db
        payload.operation = 'update_wallet';
        //payload.update_amount = parseInt(data_shipmentsummary.cost_details.total_cost);
        payload.update_amount = -100;
        //payload.update_amount = (payload.update_amount); // converting the amount to negative 
        
        console.log("update amount = "+JSON.stringify(payload.update_amount));
            
        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload))
        .then((res) => {
            //
            let update_wallet_amount = res.data.Item.wallet_amount;
            
            console.log("server has recorded the Balance = "+JSON.stringify(res.data));
        //    this.props.history.push({
        //        pathname: '/',
        //        updatebalance : update_wallet_amount
        //    });
            //window.location.replace("https://www.freightmeter.in/");
            
        })
        .catch((err) => {
    
        })

    }

    render(){

        return(
            <div>
                <h1>I am testing the wallet deduction</h1>
            </div>
        )
    }
}

export default Test_wallet_deduct;