import React from 'react';
import {AmplifySignOut } from '@aws-amplify/ui-react';
import {Link} from 'react-router-dom'

const NavigationBar = (props) => {

    return(
        <div id="content">
        
        {/*<!-- TopBar -->*/}
        <nav class="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top">
          <div class="container">
          <button id="sidebarToggleTop" class="btn btn-link rounded-circle mr-3">
            <i class="fa fa-bars toggle-bars" aria-hidden="true" onClick={props.hamburgerclick}></i>
          </button>
          <div class="topbar-right d-flex">
            <div class="d-flex align-items-center shopping-bag">
              <i class="pe-7s-shopbag"></i>
              <span class="shoping-price ml-2">₹ {props.currentbalance}</span>
            </div>
            <button type="button" data-bs-toggle="modal" data-bs-target="#rechargeModal" class="btn btn-primary recharge-btn"><span>RECHARGE</span><i className="pe-7s-piggy"></i></button>
              <div class="dropdown dropstart">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-chevron-down"></i>
                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>{props.currentuser}</li>
                <li><a href="#"><AmplifySignOut/></a></li>
                  {/*<div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary logout-btn">{props.currentuser}</button>
                    <button type="button" class="btn btn-primary logout-btn"><AmplifySignOut/></button>
    </div>*/}
                </ul>
              </div>

            </div>
          </div>          
        </nav>
      </div>
    )

}

export default NavigationBar;