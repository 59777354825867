import React, { Component } from 'react'
import Aux from '../../../HOC/Aux';
import Dimensionbox from '../Welcomepage/Dimensionbox';
import axios from 'axios';
import Amplify, { Auth,API } from 'aws-amplify';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

class InternationalExport extends Component  {

    state = {
        //package_array : [''],
        weight_display : '00',
        width_display : '00',
        lenght_display : '00',
        height_display : '00',
        packageimage : 'assets/img/pack-envelope.png',
        deliveryplace: '',
        deliverydistrict:'',
        deliverycity: '',
        deliverycountry : '',
        deliveryzip : '',
        pickupcountry : 'IN',
        pickupzip:'686001',
        pickupplace : 'Kottayam',
        pickupstate:'Kerala',
        package_count : '0',
        packageinfo: [
          {
            weight:'00',
            length:'00',
            width:'00',
            height:'00',
            packagetype:'Package' 
          }
        ]
      }

    fetchratehandler = async() => {

        console.log("I am getting a click");


        let package_info_array = [...this.state.packageinfo]

        let ChargableWeight = 0;

        
        for (let i=0; i<package_info_array.length; i++){

            let actual_weight = package_info_array[i].weight;
            let lenght = parseInt(package_info_array[i]['length']);
            let width = parseInt(package_info_array[i].width);
            let height = parseInt(package_info_array[i].height);

            let dimensionweight = ((lenght*width*height)/5000);
            
            let temp_chargableweight = 0;

            if(actual_weight > dimensionweight) temp_chargableweight = actual_weight;
            else temp_chargableweight = dimensionweight;


            ChargableWeight = (parseInt(ChargableWeight) + parseInt(temp_chargableweight));

        
        }

        console.log("Chargable Weight ="+ChargableWeight);


        let payload = {
            Pickupzip : "686001",
            PickupCountry : "INDIA",
            PickupCountryCode : '',
            DeliveryCity : this.state.deliverycity,
            DeliveryCoutnry : this.state.deliverycountry,
            DeliveryCountryCode : this.state.DeliveryCountryCode,
            DeliveryZip : this.state.deliveryzip,
            ChargableWeight : ChargableWeight,
            PackageInfo : this.state.packageinfo,
            Courier : 'Aramex'
        }

        //payload.operation = 'wallet_balance';
    
        //const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/internationalrates',JSON.stringify(payload));
    
        //console.log("response fetch aramex= "+JSON.stringify(server_response.data));

        //let AramexServerData = server_response.data; 
        let AramexServerData = ''; 

        //AramexServerData = JSON.parse(AramexServerData);

        //Here we are getting the response from the aramex ...now we need to alert the user 
        //if the response is an error 
        //we see the error is basically 2 types 
        //Type 1 : Invalid City Name 
        //Type 2 : No Zip Code
        
        

        //let ErrorStatus = AramexServerData.HasErrors
        let ErrorStatus = false

        //console.log("Error status = "+ErrorStatus);

        if(ErrorStatus === true) {

            let ErrorMessage = AramexServerData.Notifications[0].Message;
            //let ErrorMessage = server_response.data.Notifications.Message;

            console.log("Error Message = "+ErrorMessage)

            if(ErrorMessage.includes('DestinationAddress - City name is invalid')){
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Destination City',
                    text: 'please check the city name is correctly spelled for destination city',
                    
                  })
            }
            else if (ErrorMessage ==='DestinationAddress - City/Zipcode is empty'){
                Swal.fire({
                    icon: 'error',
                    title: 'No Destination City or ZIP Code',
                    text: 'please check if city name or Zip Code is Entered',
                    
                  })
            }
            else if (ErrorMessage.includes('DestinationAddress - Invalid zip code')){
                Swal.fire({
                    icon: 'error',
                    title: 'Wrong ZIP or PIN Code',
                    text: 'please check if Correct ZIP or PIN Code is Entered for the City Name',
                    
                  })
            }
            else if (ErrorMessage.includes('DestinationAddress - Invalid Zipcode')){
                Swal.fire({
                    icon: 'error',
                    title: 'No ZIP or PIN Code found',
                    text: 'please check  ZIP or PIN Code is Entered',
                    
                  })
            }
            else if (ErrorMessage.includes('ShipmentDetails.ActualWeight.Value - Value is less than zero')){
                Swal.fire({
                    icon: 'error',
                    title: 'Package Weight is less than 1 KG',
                    text: 'Package weight should be minimum 1 KG',
                    
                  })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Some error',
                    text: 'There is some error while fetching the rate from aramex server ...please email us the Destination country and City we will check and revert back',
                    
                  }) 
            }

            
        }


        const stateduplicate = {}
    
        stateduplicate.deliveryplace = this.state.deliveryplace;
        stateduplicate.DeliveryCity = this.state.deliverycity;
        stateduplicate.DeliveryCoutnry = this.state.deliverycountry;
        stateduplicate.DeliveryCountryCode = this.state.DeliveryCountryCode;
        stateduplicate.deliverydistrict = this.state.deliverydistrict;
        stateduplicate.deliverystate = this.state.deliverystate;
        stateduplicate.DeliveryZip = this.state.deliveryzip
        stateduplicate.PickupZip = this.state.pickupzip;
        stateduplicate.pickupstate = this.state.pickupstate;
        stateduplicate.pickupplace = this.state.pickupplace;
        stateduplicate.PickupCountry = this.state.pickupcountry;
        stateduplicate.FreightType = 'International';
        stateduplicate.FreightMode = 'Export';
        stateduplicate.ChargableWeight = ChargableWeight;
        stateduplicate.packageinfo = [...this.state.packageinfo];
    
        this.props.history.push({
            pathname: '/allratescompare',
            querydata : stateduplicate
        });
        

    }

    addpackagehandler = () => {
        console.log("got the click on add package")
        //let package_array = [...this.state.package_array];
        let package_info_array = [...this.state.packageinfo];
        let state_copy = this.state;
    
    
        //state_copy.package_array = package_array;
        state_copy.packageinfo = package_info_array;
    
    
        //package_array.push('');
        package_info_array.push({
          weight:'5',
          length:'15',
          width:'15',
          height:'15',
          packagetype:'Package' 
        })
    
        this.setState({
          state_copy
        }); 
      }

    dimensionhandler (event,type,index_number) {

        console.log("Details entered is ="+ type)
        console.log("Value entered is ="+ event.target.value)
        console.log("dimension enter for position"+index_number);
    
    
        if(type ==='packagetype'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].weight = event.target.value;
          temparray[index_number].packagetype = event.target.value;
    
          this.setState({
            ...this.state,
            packageinfo : temparray
          })
        }
    
        if(type ==='weight'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].weight = event.target.value;
          temparray[index_number].weight = event.target.value;
    
          this.setState({
            ...this.state,
            weight_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='width'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].width = event.target.value;
          temparray[index_number].width = event.target.value;
    
          this.setState({
            ...this.state,
            width_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='lenght'){
    
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].length = event.target.value;
          temparray[index_number].length = event.target.value;
    
          this.setState({
            ...this.state,
            lenght_display : event.target.value,
            packageinfo : temparray
          })
        }
    
        if(type ==='height'){
    
          let temparray = [...this.state.packageinfo];
    
          let packcount = this.state.package_count;
    
          //temparray[packcount].height = event.target.value;
          temparray[index_number].height = event.target.value;
    
          this.setState({
            ...this.state,
            height_display : event.target.value,
            packageinfo : temparray
          })
        }
    
      }

    render(){

        let package_array = this.state.packageinfo
        return(
            <Aux>
                 <div class="container-fluid pd-40">
          <div class="row">            
            {/*<p>Enter the Delivery Zip Code</p>*/}
            <div class="d-flex justify-content-end">
          
            <label class="switch">
              <input type="checkbox" onChange={this.changeviewhandler}/>
              <span class="slider round"></span>
            </label>
          </div>  
          <div class="col-md-6 form-sec">
            
            <div class="card">
              <div class="container">
              <p>Pickup Details</p>
              <form>
                

            <label>Zipcode</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="text" class="form-control" placeholder="Enter the Pickup Pin code" aria-describedby="sizing-addon2" defaultValue={this.state.pickupzip}/>
            </div>

             <label>Place</label>
             <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-paper-plane"></i></span>
            <input type="text" class="form-control" placeholder="waiting for the server" aria-describedby="sizing-addon2" defaultValue={this.state.pickupplace}/>
            
            </div>

            <label>State</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
            <input type="text" class="form-control" placeholder="waiting for the server" aria-describedby="sizing-addon2" defaultValue={this.state.pickupstate}/>
            
            </div>

            <label>Country</label>
            <div class="input-group">
                <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                <select class="selectpicker form-control" disabled="" readOnly>
                    <option value="IN" selected="selected">INDIA</option>
                                        
                </select>
            </div>
            
            </form>

            </div></div>
          </div>


           <div class="col-md-6 form-sec">
            
            <div class="card">
              <div class="container">
              <p>Delivery Details</p>
              <form>
                
            <label>Country</label>
            <div class="input-group">
                <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                <select class="selectpicker form-control" disabled="" onChange={(e) => this.setState({deliverycountry : e.target.value})}>
                    <option value="Select">Select the Destination Country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AR">Antigua And Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia And Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">Congo, Democratic Republic</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D\'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard Island & Mcdonald Islands</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic Of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle Of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KR">Korea</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People\'s Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">Macedonia</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States Of</option>
                    <option value="MD">Moldova</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts And Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin</option>
                    <option value="PM">Saint Pierre And Miquelon</option>
                    <option value="VC">Saint Vincent And Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome And Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">South Georgia And Sandwich Isl</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard And Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TG">Tonga</option>
                    <option value="TT">Trinidad And Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks And Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">United States Outlying Islands</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S</option>
                    <option value="WF">Wallis And Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                                        
                </select>
            </div>

            <label>City</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
            <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => this.setState({deliverycity : e.target.value})}/>
            
            </div>

            <label>Zipcode</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            {/*<input type="text" class="form-control" placeholder="Enter the delivery pin code" aria-describedby="sizing-addon2"/>*/}
            <input  type="tel" class="form-control" placeholder="Enter the pin or Zip Code" aria-describedby="sizing-addon2" 
                    maxLength="8" onChange={(e) => this.setState({deliveryzip : e.target.value})} />
            </div>

            <label>Place</label>
             <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-paper-plane"></i></span>
            <input disabled type="text" class="form-control" placeholder="Not required to enter any information" aria-describedby="sizing-addon2"
                value={this.state.deliveryplace}
            />
            
            </div>
             
            
            
            
            
            </form>

            </div></div>
          </div>


          <div class="col-md-12 form-sec">
            <p>Enter the package dimensions and weight to calculate the delivery rate</p>
            {package_array.map((row,index) => {
                return(
                    <Dimensionbox
                    key={index}
                    dimension_handler = {(e,type) => this.dimensionhandler(e,type,index)}
                    delete_package = {(e)=> this.deletepackagehandler(index)} />
                );
            })}
          
            <div class="btn-footer d-flex align-items-center flex-column flex-md-row justify-content-center">
              <button type="submit" class="btn btn-default login-btn" onClick={this.addpackagehandler} >Add Additional Package &nbsp;<i class="pe-7s-plus"></i></button>

               <button type="submit" class="btn btn-default login-btn calc-btn mt-3 mt-md-0" onClick={this.fetchratehandler}>Calculate</button>
              
            </div>
          

          </div>
            
        </div>
        
      </div>
            
            </Aux>

           
            
        )
        
    }

}

export default InternationalExport; 