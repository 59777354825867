import React, { Component } from 'react';
import axios from 'axios';
import { Auth} from 'aws-amplify';

class Test_fedex_heavy_rate extends Component {

    async componentDidMount(){
        let payload = {
            deliveryplace: 'Whitefield',
            deliverydistrict:'Bangalaore',
            deliverystate: 'Karnataka',
            deliveryzip : '560001',
            pickupzip:'686001',
            pickupplace : 'Kottayam',
            pickupstate:'Kerala',
            package_count : '0',
            packageinfo: [
            {
                weight:'65',
                length:'15',
                width:'20',
                height:'25',
                packagetype:'Package' 
            }
            ],
            "update": true,
            "registered_email": "2007.romy@gmail.com"
        }

        console.log("Fedex heavy weight payload = "+JSON.stringify(payload));

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-rate-heavyweight',JSON.stringify(payload))
        .then(res => {
            console.log(res.data);
            //console.log(res.data.body);
            
            
        })
        .catch(error => {
            console.log("some error happened Fedex Heavy Rate fetching");
        });

    }

    render(){

        return(
            <div>
                <h3>I am going to fedex Heavy weight shipment</h3>
            </div>
        )
    }

}

export default Test_fedex_heavy_rate;
