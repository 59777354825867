import React from 'react';

const Shippingpolicy = (props) => {

    return (
        <div class="container-fluid">
            <center><h1>SHIPPING POLICY</h1></center>
                <br/>
                <br/>

                <div class="divider"></div>
<h6 align="left">DOMESTIC SHIPPING</h6>
<br/>
<div class="section-title text-left">
<p>
For domestic orders, we use Fedex or DTDC for express ground service, through which customers receive their orders 1-4 business days (Monday-Friday) from the date of dispatch.
You will receive an email upon dispatch to track you shipment.
</p>
<p>Standard orders are typically processed between 1-3 business days, assuming order is placed before 2pm GMT +8.</p>
<p>We dispatch orders within 1-3 business days from the date that we receive your order and as per your credit terms.
Please note that the expedited shipping method delivery timeframes do not include order processing time. Orders placed on Friday after 3:00 PM GMT +8 will begin processing the following Monday.
Transit times do not include Saturday, Sunday or holidays</p>
<br />

<h6 align="left">INTERNATIONAL SHIPPING</h6>
<p>
All international orders will be shipped via Aramex,Fedex or DHL. Import Duties & Taxes are Due on delivery, paid to the carrier. We are not responsible for international duty and taxes.
We do not accept responsibility for orders not accepted due to failure to pay duties and taxes. We cannot make any exceptions.
International shipping, duty, and taxes are non-refundable in the event of a return/exchange. If you have questions,
please contact us at <b>romy@ronsenterprises.com</b> or whatsapp at +919400791900.
</p>
<p>
Standard shipping time is 7-15 business days.
</p>
<p>
How do I withdraw my consent?
</p>
<p>
When international orders reach their destination country/region, they may be subject to customs clearance procedures, which can cause delays beyond original delivery estimates.
If you refuse a shipment from Ronsenterprises, you are responsible for the original shipping charges, any import fees that are incurred on the package, and the cost of returning the package to Ronsenterprises.
This amount will be deducted from your merchandise refund.
</p>
<br />
<h6 align="left">For Shipping Rates </h6>
<p>
Write an email to <b>romy@ronsenterprises.com</b> with your zip code and we will send you the shipping charge from multiple courier or airfreight option which you can use as per your convinent.
</p>
<br/>

</div>

        </div>
    );

}

export default Shippingpolicy;