import React from 'react';

const Spinner = (props) => {

    return (
       <div>
            <img src="assets/img/load.gif" width="300"/>
       </div>
           
            
    
                
        
        
    );
}

export default Spinner;