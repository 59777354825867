import React, { Component } from 'react';
import axios from 'axios';

class Testfunction extends Component  {


    componentDidMount(){

        console.log(" I am going to mount the components");

    let payload = {
            flag : '00',
            weight_display : '00',
            width_display : '00',
            lenght_display : '00',
            height_display : '00',
            packageimage : 'assets/img/pack-envelope.png',
            deliveryplace: '',
            deliverydistrict:'',
            deliverystate: '',
            deliveryzip : '560001',
            pickupzip:'686001',
            pickupplace : 'Kottayam',
            pickupstate:'Kerala',
            package_count : '1',
            packageinfo: [
              {
                weight:'5',
                length:'15',
                width:'20',
                height:'25',
                packagetype:'Package' 
              }
            ]
          }

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexrate',JSON.stringify(payload)).
        then((response) => {
            console.log(response.data);

            /*
            let soap_result = response.data;

            let regex_match = /<RateReplyDetails>(.+?)<\/RateReplyDetails><RateReplyDetails>(.+?)<\/RateReplyDetails>/.exec(soap_result);

            let standard_overnight = regex_match[1];
            let express_saver = regex_match[2];

            let standard_overnight_rate = /<TotalNetChargeWithDutiesAndTaxes><Currency>INR<\/Currency><Amount>(.+?)<\/Amount><\/TotalNetChargeWithDutiesAndTaxes>/.exec(standard_overnight);
            let express_saver_rate = /<TotalNetChargeWithDutiesAndTaxes><Currency>INR<\/Currency><Amount>(.+?)<\/Amount><\/TotalNetChargeWithDutiesAndTaxes>/.exec(express_saver);

            console.log("Standard Overnight rate ="+standard_overnight_rate[1]);
            console.log("Express Saver rate ="+express_saver_rate[1]);

            //let substring1 = regex_match[1];
            let substring12 = regex_match[2];
            //console.log("Substring 1 = "+substring12);
            */
        })
        .catch((err) => {
            console.log("error from axios ="+err);
            if(err ==='Error: Network Error'){
                alert("check your internet connection");
            }
        });
    }

    render () {

        return (
            <div>
                <p>I am in Test function of Fedex Rate fetch</p>
                 
            </div>
        )
    }
}

export default Testfunction;