import React from 'react'

const Recharge = (props) => {

  return(
    
    <div class="modal fade" id="rechargeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upgrade Your Shipping Limit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6 class=" text-center">Choose the amount to recharge your account</h6>
          <div class="row g-3 align-items-center justify-content-center mt-3">
            <div class="col-auto">
              <label for="inputPassword6" class="col-form-label">Amount</label>
            </div>
            <div class="col-auto">
              <input type="text" class="form-control" onChange={props.getrecharge_amount}/>
            </div>
          </div>
          <div class="mt-3 mb-4 d-flex justify-content-center">
             <button type="button"  class="btn btn-primary recharge-btn" onClick={props.razor}>RECHARGE</button>
          </div>
        </div>
      </div>
    </div>  
  </div>

  )
    

}

export default Recharge;