import React from 'react';

const BookshipmentButton = (props) => {

  

    return (
      <div class="d-flex justify-content-center mb-5 mt-4">
        {/*<button type="submit" class="btn btn-primary form-button">BOOK SHIPMENT</button>*/}
        <button type="submit" class="btn btn-primary form-button" onClick={() => props.bookbutton()} >Book Shipment</button>
    </div>
    );

}

export default BookshipmentButton;


{/*<div class="container">
            <div class="col-md-12 btn-footer bk-shipment">
               
              <button  class="show" onClick={() => props.bookbutton()} >Book Shipment</button>
            </div>
    </div>*/}