import React, { Component } from 'react'
import Aux from '../../../HOC/Aux';
import Dimensionbox from './Dimensionbox';
import axios from 'axios';
import Amplify, { Auth,API } from 'aws-amplify';

//const Secondview = (props) => {
class Secondview extends Component  {

  state = {
    //package_array : [''],
    weight_display : '00',
    width_display : '00',
    lenght_display : '00',
    height_display : '00',
    packageimage : 'assets/img/pack-envelope.png',
    deliveryplace: '',
    deliverydistrict:'',
    deliverystate: '',
    deliveryzip : 'Wrong Zip Code',
    pickupzip:'',
    pickupplace : 'Kottayam',
    pickupstate:'Kerala',
    package_count : '0',
    packageinfo: [
      {
        weight:'45',
        length:'15',
        width:'20',
        height:'25',
        packagetype:'Package' 
      }
    ]
  }


  fetchzipcode = (event) => {

    let zip = event.target.value;

    axios.get('https://api.postalpincode.in/pincode/'+zip)
            .then(res => {
                console.log(res.data[0].PostOffice[0].State);
                const tempstate = this.state;
                tempstate.deliverystate = (res.data[0].PostOffice[0].State);
                tempstate.deliveryplace = (res.data[0].PostOffice[0].Name);
                tempstate.deliverydistrict = (res.data[0].PostOffice[0].District);
                tempstate.deliveryzip = (res.data[0].PostOffice[0].Pincode);
                this.setState({
                        tempstate
                });

            })
            .catch(error => {

                const tempstate = this.state;
                tempstate.deliveryplace = "Wrong Zip Code"
                tempstate.deliverystate = "Wrong Zip Code"
                tempstate.deliveryzip = "Wrong Zip Code"
                tempstate.deliverydistrict = "Wrong Zip Code"
                this.setState({
                    tempstate
            }); 
                console.log("some error in fetch zip happened")

            });

          }

  changeviewhandler = () => {

    /*
    if(this.state.flag === '07') {
      this.setState({
        ...this.state,
        flag : '00'
      })
    }
    else {
      this.setState({
        ...this.state,
        flag : '07'
      }) 
    }
   */
    this.props.history.push({
      pathname: '/secondview'
  });
    

  }

  fetchratehandler = () => {

    if(this.state.deliveryzip === 'Wrong Zip Code') {

      alert("Please cross check the PINCODE and correct it");
    }
    else {

    //console.log("I am going to fetch the rates"+index);
    console.log (this.props);

    const stateduplicate = {}

    stateduplicate.deliveryplace = this.state.deliveryplace;
    stateduplicate.deliverydistrict = this.state.deliverydistrict;
    stateduplicate.deliverystate = this.state.deliverystate;
    stateduplicate.deliveryzip = this.state.deliveryzip
    stateduplicate.pickupzip = this.state.pickupzip;
    stateduplicate.pickupstate = this.state.pickupstate;
    stateduplicate.pickupplace = this.state.pickupplace;
    stateduplicate.packageinfo = [...this.state.packageinfo];

    this.props.history.push({
        pathname: '/allratescompare',
        querydata : stateduplicate
    });

    }

}

  dimensionhandler (event,type,index_number) {

    console.log("Details entered is ="+ type)
    console.log("Value entered is ="+ event.target.value)
    console.log("dimension enter for position"+index_number);


    if(type ==='packagetype'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      //temparray[packcount].weight = event.target.value;
      temparray[index_number].packagetype = event.target.value;

      this.setState({
        ...this.state,
        packageinfo : temparray
      })
    }

    if(type ==='weight'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      //temparray[packcount].weight = event.target.value;
      temparray[index_number].weight = event.target.value;

      this.setState({
        ...this.state,
        weight_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='width'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      //temparray[packcount].width = event.target.value;
      temparray[index_number].width = event.target.value;

      this.setState({
        ...this.state,
        width_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='lenght'){


      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      //temparray[packcount].length = event.target.value;
      temparray[index_number].length = event.target.value;

      this.setState({
        ...this.state,
        lenght_display : event.target.value,
        packageinfo : temparray
      })
    }

    if(type ==='height'){

      let temparray = [...this.state.packageinfo];

      let packcount = this.state.package_count;

      //temparray[packcount].height = event.target.value;
      temparray[index_number].height = event.target.value;

      this.setState({
        ...this.state,
        height_display : event.target.value,
        packageinfo : temparray
      })
    }

  }

  addpackagehandler = () => {
    console.log("got the click on add package")
    //let package_array = [...this.state.package_array];
    let package_info_array = [...this.state.packageinfo];
    let state_copy = this.state;


    //state_copy.package_array = package_array;
    state_copy.packageinfo = package_info_array;


    //package_array.push('');
    package_info_array.push({
      weight:'5',
      length:'15',
      width:'15',
      height:'15',
      packagetype:'Package' 
    })

    this.setState({
      state_copy
    }); 
  }

  deletepackagehandler = (index_number) => {
    //let package_array = [...this.state.package_array]
    let package_info_array = [...this.state.packageinfo];
    let state_copy = this.state;
    

    console.log("got the click "+package_info_array.length);
    if(package_info_array.length !== 1) {
      console.log("asking to delete the package index no = "+index_number);
      //package_array.splice(index_number,1);
      package_info_array.splice(index_number,1);
      //state_copy.package_array = package_array;
      state_copy.packageinfo = package_info_array;
      this.setState({
        state_copy
      });

    }
  } 

  async fetchpickupdetails () {

    let payload = (await Auth.currentSession()).getIdToken();
    payload.operation = 'wallet_balance';

    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));

    console.log("response fetch secondview= "+JSON.stringify(server_response.data));

   this.setState(
     {
       ...this.state,
       pickupzip:server_response.data.Item.Default_address.zipcode,
       pickupplace:server_response.data.Item.Default_address.place, 
       pickupstate:server_response.data.Item.Default_address.state,
     }
   )

  }

  componentDidMount() {

    //fetch the pickup address from the user profile 
    this.fetchpickupdetails();

  
  }

  render(){

    let package_array = this.state.packageinfo

    return (
    <Aux>
        <div class="container-fluid pd-40" style="height: 100%">

<div class="d-flex justify-content-end">
{/*<!-- Rounded switch -->*/}
  <label class="switch">
    <input type="checkbox"/>
    <span class="slider round"></span>
  </label>
</div>



<div class="row ship-row">            
  <div class="col-md-6">
      <img src="assets/img/findyourperfect1.gif" class="img-fluid"/>
      <div class="loc-tags d-flex">
          <p>Kerala</p><p>Kottayam</p>
      </div>
  </div>
  <div class="col-md-6">
      <form class="ship-form">
        <div class="mb-3">
          <label for="exampleInputPackage" class="form-label">I Want to Ship a Package</label>
          <input type="text" class="form-control" id="exampleInputPackage" aria-describedby="package" placeholder="Package"/>
        </div>
        <div class="mb-3">
          <label for="inputState" class="form-label">Leaving In</label>
              <select id="inputState" class="form-select">
                <option selected="">Date</option>
                <option>...</option>
              </select>
        </div>
         <div class="mb-3">
          <label for="inputState" class="form-label">For</label>
              <select id="inputState" class="form-select">
                <option selected="">Any Duration</option>
                <option>...</option>
              </select>
        </div>
        <button type="submit" class="btn btn-primary form-button">Take me there</button>
      </form>
  </div>
  
</div>

</div>
    </Aux>
  );

}

}


export default Secondview;