import React, { Component } from 'react';
import {Row, Col, Card,Button} from 'react-bootstrap';

import axios from 'axios'
import Aux from '../../../HOC/Aux';
import DatePicker from "react-datepicker";
import Shipmentsummarybox1 from '../Shipmentsummary/Shipmentsummarybox1';
import Shipmentsummarybox2 from '../Shipmentsummary/Shipmentsummarybox2';
import "react-datepicker/dist/react-datepicker.css";
import { data, event } from 'jquery';


class Test_pickup extends Component  {

    state = {
        startDate: new Date(),
        setStartDate : new Date(),
        CompanyName : 'TestCompany',
        ContactName : 'TestContact',
        ContactNumber : 'TestNumber',
        ContactEmail : 'testemail',
        Address1 : 'address11111',
        Address2 : 'address2222',
        City : 'ciyt1qw',
        State : 'kwiwi',
        Country : 'IN',
        PickupPin : '7878779',
        TotalPackage : '2',
        TotalWeight : '12',
        InvoiceValue : '8982',
        CommodityType : 'tapes',
        ServiceType : 'Economy',
        PickupDateAndTime : '',
        ClosingTime : '',
        operation : 'FedexPickup'
    };


    inputhandler = (event,type) => {

        console.log("Getting the hit = "+event.target.value)

        if(type === 'totalpackage') {

            this.setState({
                TotalPackage :event.target.value 
            })
    
        }
        if(type === 'totalweight') {

            this.setState({
                TotalWeight :event.target.value 
            })
    
        }
        if(type === 'invoicevalue') {

            this.setState({
                InvoiceValue :event.target.value 
            })
    
        }
        if(type === 'commodity-type') {

            this.setState({
                CommodityType :event.target.value 
            })
    
        }

        if(type === 'companyname') {

            this.setState({
                CompanyName :event.target.value 
            })
    
        }
        if(type === 'contactname') {

            this.setState({
                ContactName :event.target.value 
            })
    
        }
        if(type === 'contactnumber') {

            this.setState({
                ContactNumber :event.target.value 
            })
    
        }
        if(type === 'contactemail') {

            this.setState({
                ContactEmail :event.target.value 
            })
    
        }
        if(type === 'Address1') {

            this.setState({
                Address1 :event.target.value 
            })
    
        }
        if(type === 'Address2') {

            this.setState({
                Address2 :event.target.value 
            })
    
        }
        if(type === 'City') {

            this.setState({
                City :event.target.value 
            })
    
        } 
        if(type === 'State') {

            this.setState({
                State :event.target.value 
            })
    
        } 
        if(type === 'Country') {

            this.setState({
                Country :event.target.value 
            })
    
        } 
        if(type === 'pincode') {

            this.setState({
                PickupPin :event.target.value 
            })
    
        } 
         
    }


    handleChange = (date) => {
        // this.setState({
           //startDate: date
         //  setStartDate : date
        // })
 
            
             //let str = "Thu Feb 25 2021 13:51:25 GMT+0530";
             //let str = this.state.setStartDate;
             let str = date;
 
             let regex_match = /\w{3}.(\w{3}).(\d{2}) (\d{4}) (\d{2}):(\d{2}):(\d{2})/.exec(str);
             
             //console.log("Day = "+regex_match[1])
             let day = ''         
             day = regex_match[2];
             let year = ''
             year = regex_match[3];
             let month = '';

             let hour = regex_match[4]
             let minute = regex_match[5]
             let second = regex_match[6]
 
             switch(regex_match[1]){
                 case 'Jan' : 
                      month = '01';
                      break;
                 
                 case 'Feb' : 
                     month = '02';
                     break;
                 
                 case 'Mar' : 
                     month = '03';
                     break;
                 
                 case 'Apr' : 
                     month = '04';
                     break;
                 
                 case 'May' : 
                     month = '05';
                     break;
                 
                 case 'Jun' : 
                     month = '06';
                     break;
                 
                 case 'Jul' : 
                     month = '07';
                     break;
                 
                 case 'Aug' : 
                     month = '08';
                     break;
                 
                 case 'Sep' : 
                     month = '09';
                     break;
                 
                 case 'Oct' : 
                     month = '10';
                     break;
                 
                 case 'Nov' : 
                     month = '11';
                     break;
                 
                 case 'Dec' : 
                     month = '12';
                     break;
                 
                 default : 
                     month = '0';
                     break;
             }
 
             
             let timestamp;

             timestamp = year+"-"+month+"-"+day+"T"+hour+":"+minute+":"+second+"+05:30"

             console.log("here is the time stamp format ="+timestamp)
 
             this.setState({
                 //NewEnquiry:CopyNewEnquiry,
                 //startDate : date,
                 PickupDateAndTime : timestamp,
                 setStartDate : date
             })
         
       }
    
    handleChange1 = (date) => {

        console.log("The Time ="+date)

        let str = date;
 
        let regex_match = /\w{3}.(\w{3}).(\d{2}) (\d{4}) (\d{2}):(\d{2}):(\d{2})/.exec(str);

        let hour = regex_match[4]
        let minute = regex_match[5]
        let second = regex_match[6]

        let closingtime = hour+":"+minute+":00Z ";


        this.setState({
            ClosingTime : closingtime,
            startDate : date
        })

    }

    pickupsubmit_handler = async() => {
        console.log("got the click");
        let payload = {}

        payload = this.state;

        console.log("Payload info ="+JSON.stringify(payload));

        const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexservice',JSON.stringify(payload));

        console.log("response = "+JSON.stringify(server_response.data));

        //wait for the response and if the response is failed ..show the message to the user the request is 
        //failed and need to look at the parameter 
        //if the response is sucess then we need to email the pickup reference number to the email using a post request

        var str = server_response.data;
        var response = str.includes("faliure");

        if(response == true) console.log("The Request is failed")
        else {
            console.log("the request is a sucess");

            let payload = {
                refnumber : server_response.data,
                companyname : 'Ferrari',
                contactname : 'Jacki',
                contactnumber : '89089102'
            }

            axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/emailnotification',JSON.stringify(payload)).
            then(res => {

                console.log("Email send to the sales = "+JSON.stringify(res))
            })
            .catch(err => {
                console.log("Some error ="+err)
            }) 

        }

        /*
        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexrate',JSON.stringify(payload)).
            then(res => {

            })
            .catch(err => {
                
            })
        */
        
            this.props.history.push({
                pathname: '/'
            }); 

    }

    render() {

        //const {startDate} = this.state;

        return(
            <Aux>
            <div class="container-fluid pd-40">
                <div class="row">            
                    <p class="mb-4 " align="center">Enter the Fedex Pick Up Infomration</p>

                                
                    <div class="col-md-6">
            
                    <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header>
                                <br/>
                                <center>
                                <Card.Title as='h5'>Pickup Company Details</Card.Title>
                                </center>
                                
                            </Card.Header>
                            <Card.Body>
                            <label>Company Name</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Company Name" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"companyname")}/>
                                </div>
                            <label>Contact Person</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Contact Person" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"contactname")}/>
                                </div>
                            <label>Contact Number</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Contact Number" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"contactnumber")}/>
                                </div>
                            <label>Contact Email</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Email" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"contactemail")}/>
                                </div>
                            <label>Pick UP Address</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Address 1" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"Address1")}/>
                                    <input type="text" class="form-control" placeholder="Address 2" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"Address2")}/>
                                </div>
                            
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="City" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"City")}/>
                                    <input type="text" class="form-control" placeholder="State" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"State")}/>
                                </div>
                            <label>Pick UP Pincode</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="tel" class="form-control" placeholder="Enter Pin Code" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"pincode")}/>
                                </div>
                            {/*<label>Country</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <select class="selectpicker form-control" >
                                    <option defaultValue="IN">INDIA</option>
                                    
            </select>
                                </div> 
                            */}
                            </Card.Body>
                        </Card>
                    </Col>
                      </Row>
                    </div>

                    <div class="col-md-6">
            
                    <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header>
                            <br/>
                                <center>
                                <Card.Title as='h5'>Package Details</Card.Title>
                                </center>
                            </Card.Header>
                            <Card.Body>
                            <label>Total Package</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Number of Package" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"totalpackage")}/>
                                </div>
                            <label>Total Weight</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Weight in KG" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"totalweight")}/>
                                </div>
                            <label>Invoice Value</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Invoice Value [INR]" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"invoicevalue")}/>
                                </div>
                            <label>Commodity Type</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Enter the Commodity Type" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"commodity-type")}/>
                                </div>
                            {/*<label>Service Type</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
                                    <input type="text" class="form-control" placeholder="Weight in KG" aria-describedby="sizing-addon2" onChange={(e) => this.inputhandler(e,"pincode")}/>
                            </div>*/}
                            <label>Pick Up Date and Time</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                                    {/*<input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" />*/}
                                    <DatePicker
                                            selected={this.state.setStartDate}
                                            onChange={this.handleChange}
                                            className="form-control"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeCaption="time"
                                        />
                                </div>
                            <label>Company Closing Time</label>
                                <div class="input-group">
                                    <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                                    {/*<input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" />*/}
                                    <DatePicker 
                                    selected={this.state.startDate}
                                    onChange={this.handleChange1}
                                    className="form-control"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    dateFormat="h:mm aa"
                                    timeCaption="time"
                                />
                                </div>
                            
                            </Card.Body>
                        </Card>
                    </Col>
                      </Row>
                    </div>

            <center><Button onClick={this.pickupsubmit_handler}>SUBMIT</Button></center>

                    
                </div>

        
        </div>
            </Aux>
        );
    }
}

export default Test_pickup;