import React from 'react';
import Aux from '../../../HOC/Aux';

const Add_or_check = (props) => {

    return (
            <Aux>
                    <div class="content">
            <div class="container shipp-dimension" id="pop-up-content">
              <div class="col-md-6 col-sm-6"> 
        	     
                        <div class="input-group">
                                <div class="col-md-6 col-xs-12">
                                        <button onClick={props.additional_package}>Add Additional Package</button>
                                </div>
                        </div>
              </div>
            

              <div class="col-md-6  dimension-sec col-sm-6">
                        
                        <div class="input-group">
                                <div class="col-md-6 col-xs-12">
                                        <button onClick={props.calculate}>Calculate</button>
                                </div>
                        </div>

              </div>



            
            </div>
          </div>
            </Aux>
            
    );
}

export default Add_or_check;