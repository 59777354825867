import React from 'react';

const ShipmentInsurance = (props) => {

    return (

         
          <div class="col-md-6 form-sec ">
            
            <div class="card card2">
              <div class="container">
              <p>Insurance</p>
              <form>
              <label>Goods Description</label>
            <div class="description-box">
                     <input type="text" class="form-control" placeholder="Enter Goods Description Here" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'goodsdesription')}/>
              </div>
              
            <label>Invoice Value</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="text" class="form-control" placeholder="Enter Value" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'invoicevalue')}/>
            </div>

            <label>Shipment Reference</label>
            <div class="input-group deliver-pin">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="text" class="form-control" placeholder="Enter Reference" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'shipmentreference')}/>
            </div>             

            <label>Insurance</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
            <select class="selectpicker form-control" onChange={(e) => props.insurance(e,"insurance")}>
                                    <option defaultValue="NO">NO</option>
                                    <option value="YES">YES</option>
                                    
            </select>
            </div>


          </form>

            </div></div>
          </div>
        
    );
}

export default ShipmentInsurance;


{/*<div class="col-md-6 form-sec">
           <div class="card">
              <div class="container">
              <p>Goods Information and Insurance </p>
              <form>
                <label>Goods Description*</label>
                <div class="input-group">
                     <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                     <input type="text" class="form-control" placeholder="Enter Goods Description Here" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'goodsdesription')}/>
                </div>
               

            <label>Invoice Value*</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="tel" class="form-control" placeholder="Enter value" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'invoicevalue')}/>
            </div>

               <label>Shipment Reference</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
            <input type="text" class="form-control" placeholder="Enter Reference" aria-describedby="sizing-addon2" onChange={(e) => props.invoicevalue(e,'shipmentreference')}/>
            </div>

             <label>Insurance*</label>
            <div class="input-group">
            <span class="input-group-addon" id=""><i class="pe-7s-flag"></i></span>
            <select class="selectpicker form-control" onChange={(e) => props.insurance(e,"insurance")}>

                                    <option defaultValue="NO">NO</option>
                                    <option value="YES">YES</option>                            
                                    
                                  </select>
            </div>

</form>

            </div></div>
    </div>*/}