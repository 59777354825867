import React, { Component } from 'react';
import axios from 'axios';
import Aux from '../../../HOC/Aux';
import { connect } from 'react-redux';
import Amplify, { Auth,API } from 'aws-amplify';
import Contact_card from './Contactcard';
import Content from './Content';

import * as actionTypes from '../../../store/actions';

class Showtracking extends Component  {

    state = {
        loading: 'true',
        tracking: "waiting....",
        pdfbase64 : 'base64',
        pdfcopy : [],
        Courier_company : '',
        Courier_contact_personname : '',
        Courier_contact_primary_number : '',
        Courier_contact_number_2 : '',
        Courier_contact_whatsapp : '',
        Courier_contact_email : '',
        Courier_contact_address : '',
        Courier_logo : ''
    }

    async bookdtdc() {

        let idtoken = (await Auth.currentSession()).getIdToken();

        const payload = this.props.location.querydata2;
        payload.registered_email = idtoken.payload.email;


        let courier_array = [];
                let courier_details = {
                    courier_name: '',
                    address: ''.Contact_name,
                    contact_name: ''
                };

            courier_array = payload.courier_localinfo;

            payload.courier_localinfo.map(row => {
                        if(row.Courier === 'DTDC'){
                            return (
                                courier_details.courier_name = 'DTDC',
                                courier_details.address = row.Address,
                                courier_details.contact_name = row.Contact_name,
                                courier_details.logo = row.Logo
                            )
                        }
                    })
            
            console.log("local Courier Info = "+ JSON.stringify(courier_details));



        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/resouce3',JSON.stringify(payload))
            .then(res => {
                //let temp = res.config.data;
                //let temp = JSON.parse(res.config.data);
                //console.log("In the result page = "+res.data);
                console.log("In the result page = "+JSON.stringify(res.data));

                this.setState({
                    ...this.state,
                    loading:'true',
                    tracking : res.data,
                    Courier_company : 'DTDC', 
                    Courier_contact_personname : courier_details.contact_name,
                    Courier_contact_primary_number : '',
                    Courier_contact_number_2 : '',
                    Courier_contact_whatsapp : '',
                    Courier_contact_email : '', 
                    Courier_logo : courier_details.logo,
                    Courier_contact_address : courier_details.address
                })

            
            })
            .catch(err => {
                console.log("some error happened on dtdc fetching"+err);
            });

    }
    async bookfedex(){

                let idtoken = (await Auth.currentSession()).getIdToken();

                const payload = this.props.location.querydata2;

                let courier_array = [];
                let courier_details = {
                    courier_name: '',
                    address: ''.Contact_name,
                    contact_name: ''
                };

            courier_array = payload.courier_localinfo;

            payload.courier_localinfo.map(row => {
                        if(row.Courier === 'FEDEX'){
                            return (
                                courier_details.courier_name = 'FEDEX',
                                courier_details.address = row.Address,
                                courier_details.contact_name = row.Contact_name,
                                courier_details.logo = row.Logo
                            )
                        }
                    })
            
            console.log("local Courier Info = "+ JSON.stringify(courier_details));

        /*
            let payload = {
                "wallet_balance": "",
                "courier_info": {
                "courier_name": "Fedex",
                "courier_mode": "Express Saver",
                "chargable_weight": 2
                },
                "pickup_details": {
                "pickup_company": "Ronsenterprises",
                "pickup_address1": "Adichira",
                "pickup_address2": "Thellakom P O",
                "pickup_zipcode": "686630",
                "pickup_place": "adichira",
                "pickup_state": "Kerala",
                "pickup_contact_person": "Jayalakshmi",
                "pickup_contact_number": "9946516960",
                "pickup_contact_email": "office@ronsenterprises.com"
                },
                "delivery_details": {
                "delivery_company": "Hyatt Regency Mumabi",
                "delivery_address1": "Sahar Airport Rd",
                "delivery_address2": "Ashok Nagar, Andheri East",
                "delivery_zipcode": "560010",
                "delivery_place": "Industrial Estate (Bangalore)",
                "delivery_state": "Karnataka",
                "delivery_contact_person": "Kanojia",
                "delivery_contact_number": "944791800",
                "delivery_contact_email": "kanojia@gmail.com"
                },
                "package_info": [
                {
                    "weight": "2",
                    "length": "10",
                    "width": "10",
                    "height": "15",
                    "packagetype": "Package"
                }
                ],
                "insurance_details": {
                "goods_description": "",
                "invoice_value": "2000",
                "shipment_reference": "",
                "insurance": "NO"
                },
                "cost_details": {
                "transportation_cost": "140.4",
                "insurance_cost": "0",
                "total_cost": "140.4"
                },
                "update": true,
                "registered_email": "2007.romy@gmail.com"
            }
        */

            payload.registered_email = idtoken.payload.email;
            
            


            console.log("payload to send = "+JSON.stringify(payload));

            
            axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-booking',JSON.stringify(payload))
                .then(res => {
                    //let temp = res.config.data;
                    //let temp = JSON.parse(res.config.data);
                    //console.log("In the result page = "+res.data);
                    console.log("In the result page = "+JSON.stringify(res.data));
                    /*
                    this.setState({
                        tracking: res.data.tracking_number,
                        pdfbase64 : res.data.pdf_base64

                    })
                    */

                    
                   
                    
                    this.setState({
                        ...this.state,
                        loading:'true',
                        tracking: res.data.tracking_number,
                        pdfbase64 : res.data.pdf_base64,
                        Courier_company : 'FEDEX',
                        Courier_contact_personname : courier_details.contact_name,
                        Courier_contact_primary_number : '',
                        Courier_contact_number_2 : '',
                        Courier_contact_whatsapp : '',
                        Courier_contact_email : '', 
                        Courier_logo : courier_details.logo,
                        Courier_contact_address : courier_details.address
    
                    })
                    
                })
                .catch(err => {
                    console.log("some error happened");
                }); 
                

    }

    async bookmultipack_fedex(){

        let idtoken = (await Auth.currentSession()).getIdToken();

        const payload = this.props.location.querydata2;

        let courier_array = [];
        let courier_details = {
            courier_name: '',
            address: ''.Contact_name,
            contact_name: '',
            logo : ''
        };

    courier_array = payload.courier_localinfo;

    payload.courier_localinfo.map(row => {
                if(row.Courier === 'FEDEX'){
                    return (
                        courier_details.courier_name = 'FEDEX',
                        courier_details.address = row.Address,
                        courier_details.contact_name = row.Contact_name,
                        courier_details.logo = row.Logo
                    )
                }
            })
    
    console.log("local Courier Info = "+ JSON.stringify(courier_details));



    payload.registered_email = idtoken.payload.email;
    
    


    console.log("payload to send = "+JSON.stringify(payload));

    
    axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-booking-multiple-package',JSON.stringify(payload))
        .then(res => {
            //let temp = res.config.data;
            //let temp = JSON.parse(res.config.data);
            //console.log("In the result page = "+res.data);
            console.log("In the result page = "+JSON.stringify(res.data));
            /*
            this.setState({
                tracking: res.data.tracking_number,
                pdfbase64 : res.data.pdf_base64

            })
            */

            
           let master_pdf = [];

           master_pdf = res.data.pdf_base64.shift(); 
            
            this.setState({
                ...this.state,
                loading: 'true',
                tracking: res.data.tracking_number[0],
                //pdfbase64 : res.data.pdf_base64[0],
                pdfbase64 : master_pdf,
                pdfcopy : res.data.pdf_base64,
                Courier_company : 'FEDEX',
                Courier_contact_personname : courier_details.contact_name,
                Courier_contact_primary_number : '',
                Courier_contact_number_2 : '',
                Courier_contact_whatsapp : '',
                Courier_contact_email : '', 
                Courier_logo : courier_details.logo,
                Courier_contact_address : courier_details.address

            })
            
        })
        .catch(err => {
            console.log("some error happened");
        }); 
        

}

  async  courierinfohandler(){

    let payload = (await Auth.currentSession()).getIdToken();
  
    payload.operation = 'wallet_balance';
    //payload.update_amount ='300';
  
    //console.log("IDtoken : "+JSON.stringify(payload));
  
    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));
  
    console.log("response = "+JSON.stringify(server_response.data));

    }

async update_wallet(){

    const data_shipmentsummary = this.props.location.querydata2;

    //console.log("data shipmentsummary = "+JSON.stringify(data_shipmentsummary));

    

    //const payload = {
        //        operation : 'update_wallet',
        //        update_amount : amount_fromrazor
    //    }
    
    let payload = (await Auth.currentSession()).getIdToken();

    // amount to be updated in the waller of the dynammo db
    //payload.operation = 'wallet_balance';
    payload.operation = 'deduct_wallet';
    payload.update_amount = parseInt(data_shipmentsummary.cost_details.total_cost);
    //payload.update_amount = 100;
    //payload.update_amount = (payload.update_amount); // converting the amount to negative 
    
    console.log("update amount = "+JSON.stringify(payload.update_amount));
        
    axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload))
    .then((res) => {
        //
        let update_wallet_amount = res.data.Item.wallet_amount;
        
        console.log("server has recorded the Balance = "+JSON.stringify(res.data));
        this.props.updatewallet(update_wallet_amount);
    //    this.props.history.push({
    //        pathname: '/',
    //        updatebalance : update_wallet_amount
    //    });
        //window.location.replace("https://www.freightmeter.in/");
        
    })
    .catch((err) => {

    })

    
    
}

  async componentDidMount(){

    const payload = this.props.location.querydata2;

    console.log("pyalod receievd ="+JSON.stringify(payload));

    if(payload.courier_info.courier_name === 'Fedex') {

        //if package is more than one it is multi package booking so we redirect the link to multi package 
        //booking. 
        if(payload.package_info.length > 1) {
            this.bookmultipack_fedex();
        }
        else {
            this.bookfedex();
        }

        
        //this.courierinfohandler();
    }
    else if(payload.courier_info.courier_name === 'DTDC') {
        this.bookdtdc(); 
    } else {
        alert("some error in the book, Contact the admin");
    }

    //update the wallet amount 
        this.update_wallet();

  }

  printpdfhandler = () =>{
      
    //let varriable = this.state.trial;
      //console.log("going to fetch the state avalue "+ varriable)
    var pdfResult= this.state.pdfbase64;       
    let pdfWindow = window.open("");
    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

  }

  printpdfsalve_handler = (index) =>{
      
    //let varriable = this.state.trial;
      console.log("going to fetch the state avalue "+ index)
      
    var pdfResult= this.state.pdfcopy[index];       
    let pdfWindow = window.open("");
    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

  }

    render(){

        
        let salvepdf = [...this.state.pdfcopy]

        //salvepdf = salvepdf.shift();

        
        

        return(
            (this.state.loading ==='true') ? 
                <Aux>
                   <div class="container-fluid pd-40 mb-5">
                        <div class="card card2 mb-5 pt-4 pb-4 pb-19">            
                            <div class=" row congrats-box d-flex justify-content-center align-items-center">
                                <div class="cont-card ">
                                    <div class="success-card align-items-center">
                                        <p><i class="fa fa-check-circle" aria-hidden="true"></i></p>
                                        <p class="mt-3">Congrats !</p>
                                        <p class="fw-400">Your Shipment Booked Successfully</p>
                                        <p class="">Tracking Number<span>:&nbsp;{this.state.tracking}</span></p>
                                        <div class="d-flex justify-content-center">
                                            {/*<button class="btn btn-primary print">Print</button>*/}
                                            {(this.state.Courier_company === 'FEDEX') ? 
                                                <button class="btn btn-primary print" onClick={this.printpdfhandler}> Master Print</button>
                                            : 
                                            ''
                                            }
                                            {salvepdf.map((row,index) => {
                                                return(
                                                    <div>
                                                    {<button class="btn btn-primary print" onClick={() => this.printpdfsalve_handler(index)}>Print</button>}
                                                </div>
                                                );     
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row d-flex justify-content-center">
                                <Contact_card
                                    Name = {this.state.Courier_contact_personname}
                                    Number = {this.state.Courier_contact_primary_number}
                                    Email = {this.state.Courier_contact_email}
                                    Whatsapp = {this.state.Courier_contact_whatsapp}
                                    Mobile = {this.state.Courier_contact_primary_number}
                                    Address = {this.state.Courier_contact_address}
                                    Logo = {this.state.Courier_logo} 
                                />
                                
                                
                            </div>
                        </div>


      
                    </div> 
                </Aux>
                
        : 

        <div>
            <center><img src="assets/img/load.gif" style={{width:'500px'}}/></center>
            
        </div>
            
        );
    }
}

const mapStateToprops = state => {
    return {
        walletamount : state.WalletBalance
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        updatewallet : (amount) => dispatch({ type: actionTypes.UPDATE_WALLET,payload:amount} )
    }
  }

export default connect(mapStateToprops,mapDispatchToProps)(Showtracking);