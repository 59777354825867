import React from 'react';
import Aux from '../../../HOC/Aux';

const Dimensionbox = (props) => {

    return(
        <Aux>
            <div class="card">
              <div class="ftr-form">
                <div class="row">
              <div class="col-md-2 no-padding">
                <label>Choose package type</label>
                <div class="input-group deliver-pin">
            
            <select class="selectpicker form-control" onChange={(e) => props.dimension_handler(e,'packagetype')} >
                                    <option value="" selected="selected">Package</option>
                                    <option value="AF">Document</option>                      
                                   
                                  </select>
            </div>

            </div>

            <div class="col-md-2 no-padding">
              <label>Length</label>
                <div class="input-group">
            
            <input type="tel" class="form-control" placeholder="cm" aria-describedby="sizing-addon2" onChange={(e) => props.dimension_handler(e,'lenght')}/>
            </div>

            </div>

            <div class="col-md-2 no-padding">
              <label>Width</label>
                <div class="input-group">
            
            <input type="tel" class="form-control" placeholder="cm" aria-describedby="sizing-addon2" onChange={(e) => props.dimension_handler(e,'width')}/>
            </div>

            </div>

            <div class="col-md-2 no-padding">
              <label>Height</label>
                <div class="input-group">
            
            <input type="tel" class="form-control" placeholder="cm" aria-describedby="sizing-addon2" onChange={(e) => props.dimension_handler(e,'height')}/>
            </div>

            </div>

            <div class="col-md-2 no-padding">
              <label>Weight</label>
                <div class="input-group">
            
            <input type="tel" class="form-control" placeholder="kg" aria-describedby="sizing-addon2" onChange={(e) => props.dimension_handler(e,'weight')}/>
            </div>

            </div>

            <div class="col-md-2 no-padding d-flex justify-content-center align-items-center flex-column">
              <label>Delete</label>
                <div class="input-group justify-content-center">
            <span onClick={props.delete_package}><i class="fa fa-trash" aria-hidden="true"></i></span>
            </div>

            </div>
            </div>
          </div></div>

        </Aux>
    );

}

export default Dimensionbox;