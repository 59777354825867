import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import axios from 'axios';
import { Auth} from 'aws-amplify';
import Compareratebody from './Compareratebody';
import Spinner from './Spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//import 'sweetalert2/dist/sweetalert2.css';
import {Row, Col, Card,Button} from 'react-bootstrap';


class CompareRate extends Component {

    state = {
        rates_fromserver : [],
        wallet : ''
    }


async aramex_handler (){

    const payload = this.props.location.querydata;

    console.log("I am going to fetch the aramex rates now chao ");

    let package_info_array = payload.packageinfo;

    let ChargableWeight = 0;

    for (let i=0; i<package_info_array.length; i++){

        let actual_weight = package_info_array[i].weight;
        let lenght = parseInt(package_info_array[i]['length']);
        let width = parseInt(package_info_array[i].width);
        let height = parseInt(package_info_array[i].height);

        let dimensionweight = ((lenght*width*height)/5000);
        
        let temp_chargableweight = 0;

        if(actual_weight > dimensionweight) temp_chargableweight = actual_weight;
        else temp_chargableweight = dimensionweight;


        ChargableWeight = (parseInt(ChargableWeight) + parseInt(temp_chargableweight));

    
    }

    console.log("Chargable Weight ="+ChargableWeight);


    let server_payload = {
        Pickupzip : payload.PickupZip,
        PickupCity : payload.pickupplace,
        PickupCountry : payload.PickupCountry,
        PickupCountryCode : payload.PickupCountry,
        DeliveryCity : payload.DeliveryCity,
        DeliveryCoutnry : payload.DeliveryCoutnry,
        DeliveryCountryCode : payload.DeliveryCountryCode,
        DeliveryZip : payload.DeliveryZip,
        ChargableWeight : payload.ChargableWeight,
        PackageInfo : payload.packageinfo,
        FreightMode : payload.FreightMode,
        FreightType : payload.FreightType,
        Courier : 'Aramex'
    }

    console.log("aramex payload ="+ JSON.stringify(server_payload));


    //payload.operation = 'wallet_balance';

    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/internationalrates',JSON.stringify(server_payload));

    console.log("response fetch aramex server= "+JSON.stringify(server_response.data));

    let AramexServerData = server_response.data; 

    //AramexServerData = JSON.parse(AramexServerData);

    //Here we are getting the response from the aramex ...now we need to alert the user 
    //if the response is an error 
    //we see the error is basically 2 types 
    //Type 1 : Invalid City Name 
    //Type 2 : No Zip Code
    
    

    let ErrorStatus = AramexServerData.HasErrors

    //console.log("Error status = "+ErrorStatus);

    if(ErrorStatus === true) {

        let ErrorMessage = AramexServerData.Notifications[0].Message;
        //let ErrorMessage = server_response.data.Notifications.Message;

        console.log("Error Message = "+ErrorMessage)

        if(ErrorMessage.includes('DestinationAddress - City name is invalid')){
            Swal.fire({
                icon: 'error',
                title: 'Invalid Destination City',
                text: 'please check the city name is correctly spelled for destination city',
                
              })
        }
        else if (ErrorMessage ==='DestinationAddress - City/Zipcode is empty'){
            Swal.fire({
                icon: 'error',
                title: 'ARAMEX ERROR - No Destination City or ZIP Code',
                text: 'please check if city name or Zip Code is Entered',
                
              })
        }
        else if (ErrorMessage.includes('DestinationAddress - Invalid zip code')){
            Swal.fire({
                icon: 'error',
                title: 'Wrong ZIP or PIN Code',
                text: 'please check if Correct ZIP or PIN Code is Entered for the City Name',
                
              })
        }
        else if (ErrorMessage.includes('DestinationAddress - Invalid Zipcode')){
            Swal.fire({
                icon: 'error',
                title: 'ARAMEX ERROR - No ZIP or PIN Code found',
                text: 'please check  ZIP or PIN Code is Entered',
                
              })
        }
        else if (ErrorMessage.includes('ShipmentDetails.ActualWeight.Value - Value is less than zero')){
            Swal.fire({
                icon: 'error',
                title: 'Package Weight is less than 1 KG',
                text: 'Package weight should be minimum 1 KG',
                
              })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Some error',
                text: 'There is some error while fetching the rate from aramex server ...please email us the Destination country and City we will check and revert back',
                
              }) 
        }

        
    }
    else {

        let temparray = [...this.state.rates_fromserver];

        let temp_results = {
            courier: 'Aramex',
            type : "PRIORITY",
            mode : 'Express',
            logo : 'assets/img/aramex.jpg',
            pickupdate : 'today',
            currency : AramexServerData.TotalAmount.CurrencyCode, 
            price : AramexServerData.TotalAmount.Value,
            chargableweight : ChargableWeight,
            url : 'fedexrate-url',
            id:Math.floor(Math.random() * 10) + 1
        }

        temparray.push(temp_results);

        this.setState({
            rates_fromserver : temparray 
        }); 
    }

    

} 

  async dtdc_fetch_handler(){

        const payload = this.props.location.querydata;
        console.log("Payload = "+JSON.stringify(payload));

        console.log("Going to Fetch the DTDC rates from the server");

        const myInit = { 
            headers: { 
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
          }

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/resource1',JSON.stringify(payload),myInit)
        .then(res => {
            console.log(res.data);
            //console.log(res.data.body);
            const arrayresult = res.data;

            let temparray = [...this.state.rates_fromserver];

            temparray.push(...arrayresult);

            
            this.setState({
                rates_fromserver : temparray 
            });
            
        })
        .catch(error => {
            console.log("some error happened DTDC fetching");
        });

    }

    async professional_fetch_handler(){

        const payload = this.props.location.querydata;
        console.log("Payload = "+JSON.stringify(payload));

        console.log("Going to Professional the Professional rates from the server");

        const myInit = { 
            headers: { 
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
          }

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/professionalcourier',JSON.stringify(payload),JSON.stringify(myInit))
        .then(res => {
            console.log(res.data);
            //console.log(res.data.body);
            const arrayresult = res.data;

            let temparray = [...this.state.rates_fromserver];

            temparray.push(...arrayresult);

            
            this.setState({
                rates_fromserver : temparray 
            });
            
        })
        .catch(error => {
            console.log("some error happened Professional fetching");
        });

    }

    fedex_fetch_handler(){

        const payload = this.props.location.querydata;
        console.log("Payload = "+JSON.stringify(payload));
        console.log("Going to Fetch the Fedex rates from the server");

        //axios.get('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexrate').
        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexrate',JSON.stringify(payload)).
        then((response) => {
            console.log(response.data);

            if(response.data ==='some error in lamda function happended') {
                //do nothing 
                
            }
            else {
                let soap_result = response.data;

                let temparray = [...this.state.rates_fromserver];

                temparray.push(...soap_result);

                this.setState({
                    rates_fromserver : temparray 
                });

            }            

        })
        .catch((err) => {
            console.log("error from axios ="+err);
            if(err ==='Error: Network Error'){
                alert("check your internet connection");
            }
            else {
                console.log("some error in fedex fetching");
            }
        });

    }

    PreviousPageHandler = () => {

        this.props.history.push({
            pathname: '/',
            //querydata1 : mytransfer_data
        });
    }

    fedex_heavyweight_fetch_handler(){

        const payload = this.props.location.querydata;
        console.log("Payload = "+JSON.stringify(payload));
        console.log("Going to Fetch the Fedex heavy rates from the server");

        //axios.get('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedexrate').
        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-rate-heavyweight',JSON.stringify(payload)).
        then((response) => {
            console.log(response.data);

            if(response.data ==='some error in lamda function for heavy weight  happended') {
                //do nothing 
                
            }
            else {
                let soap_result = response.data;

                let temparray = [...this.state.rates_fromserver];

                temparray.push(...soap_result);

                this.setState({
                    rates_fromserver : temparray 
                });

            }            

        })
        .catch((err) => {
            console.log("error from axios ="+err);
            if(err ==='Error: Network Error'){
                alert("check your internet connection");
            }
            else {
                console.log("some error in fedex fetching");
            }
        });

    }

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type
        });
    };
    makebookinghandler = (params) => {
        console.log("Tags printed"+params);
       
        const awsresults = this.state.rates_fromserver;

        let rowpassed = []
        
        awsresults.map(row => {
            if(row.id === params) {
                //console.log("I got the row"+row.id);
                rowpassed = row;
            }
        });

        console.log("row selected : "+JSON.stringify(rowpassed));
        
        const payload = this.props.location.querydata;

        console.log("data paased = "+JSON.stringify(payload))

        let mytransfer_data = {
            shipmentdata : payload,
            cost : '50',
            rate_selected : [],
            package_array : payload.packageinfo
        }

        //mytransfer_data.rate_selected = [...this.state.dtdc_rates_fromserver];
        mytransfer_data.rate_selected = rowpassed;

        if(rowpassed.price >this.state.wallet) {
            //alert("Not Enought Balance to Book the Freight...Please recharge!!!");
            
            this.sweetAlertHandler({title: 'Please Recharge!', type: 'error', text: 'Not Enought Balance to Book the Freight'})
        }
        else {

            this.props.history.push({
                pathname: '/overview',
                querydata1 : mytransfer_data,
                querydata2 : payload
            });

        }
        
        
        //console.log("aws results = "+JSON.stringify(awsresults));
    
    }




async fetchmybalance() {

    let payload = (await Auth.currentSession()).getIdToken();
      
    payload.operation = 'wallet_balance';

    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));

    console.log("response = "+JSON.stringify(server_response.data));
    this.setState(
        {
            ...this.state,
            wallet : server_response.data.Item.wallet_amount
        }
    );

}

 async componentDidMount(){

        const payload = this.props.location.querydata;
        console.log("Payload = "+JSON.stringify(payload));

        this.fetchmybalance();


        if(payload.FreightType==='International'){
            this.aramex_handler();
        }
        else {

            this.dtdc_fetch_handler();
            this.professional_fetch_handler();
            this.fedex_fetch_handler();
            this.fedex_heavyweight_fetch_handler();
        }

        
        

        //once all the details has been received evoke the state reset and display the results here
       
    }

    render() {

        const awsresults = this.state.rates_fromserver;

        return (
        <Aux>
            <div class="container-fluid pd-40 pd-mob-20" style={{height: "100%"}}>
                <div class="row">
                    <h6>Choose &amp; Book Courier Partner !!</h6>
                    <div class="d-flex justify-content-between mt-3">
                            {/*<button type="submit" class="btn btn-primary back-btn"></button>*/}
                            <p></p>
                            <Button className="float-right btn btn-primary back-btn" size="sm" onClick={this.PreviousPageHandler}>BACK</Button>
                    </div>
                    {(awsresults.length) ? '': <Spinner/>}  
                </div>

                <div class="row mb-5">

            {/*<!-- ROW1 -->*/}

            {awsresults.map((row) => { 
                                let days = ''
                                if(row.type==='DTDC Air' || row.type==='Document' || row.type==='Stanard Overnight') {
                                        days = '2-3';
                                }
                                else if(row.type==='Express Saver - Heavy Weight') {
                                    days = '5-10';
                                }
                                else {
                                    days ='3-5';
                                }
                                return (
                                    <Compareratebody
                                    key={row.id}
                                    id={row.id}
                                    courier={row.courier}
                                    type={row.type}
                                    loadingdate={row.pickupdate}
                                    transitdays={days}
                                    currencycode={(row.currency==='INR') ? 'INR' : row.currency}
                                    //{/*transitdays={(row.type==(('DTDC Air')||('Document')||('Stanard Overnight'))) ? '2-3' : '3-5'}*/}
                                    price={row.price}
                                    chargeweight={row.chargableweight}
                                    logo = {row.logo}
                                    submit={(tags) => this.makebookinghandler(tags)}
                                    />
                                );
                            })}
            {/*<!-- ROW1 ENDS-->*/}

          </div>
        
      </div>
            </Aux>
        );
    }

}

/*
{awsresults.map((row) => { 
                    return (
                        <Compareratebody/>
                    );
                })}

*/

export default CompareRate; 