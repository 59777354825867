import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import axios from 'axios';
import { connect } from 'react-redux';
import Amplify, { Auth,API } from 'aws-amplify';
import Shipmentsummarybox1 from './Shipmentsummarybox1';
import ShipmentInsurance from './ShipmentInsurance';
import PaymentSummary from './PaymentSummary';
import BookshipmentButton from './BookshipmentButton';
import Shipmentsummarybox2 from './Shipmentsummarybox2';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'sweetalert2/dist/sweetalert2.css';
import {Row, Col, Card,Button} from 'react-bootstrap';

class Shipmentsummary extends Component {

    state = {
          wallet_balance : "",
          booking_flag : [0,0,0,0,0,0,0,0],
          courier_info : {
              courier_name : 'DTDC',
              courier_type: 'light',
              courier_mode : 'Surface',
              chargable_weight : '5 KG'
          },
          pickup_details : {
              pickup_company:"",
              pickup_address1:"",
              pickup_address2:"",
              pickup_zipcode:"",
              pickup_place:"",
              pickup_state:"",
              pickup_contact_person:"",
              pickup_contact_number:"",
              pickup_contact_email:""
          },
          delivery_details : {
              delivery_company:"",
              delivery_address1:"",
              delivery_address2:"",
              delivery_zipcode:"",
              delivery_place:"",
              delivery_state:"",
              delivery_contact_person:"",
              delivery_contact_number:"",
              delivery_contact_email:"" 
          },
          package_info : [],
          insurance_details : {
              goods_description:"",
              invoice_value:'',
              shipment_reference:"",
              insurance:"NO"
          },
          cost_details : {
              transportation_cost:"",
              insurance_cost:"",
              total_cost:""
          },
          courier_localinfo : '',
          update:false
    }

    insurancehandler = (event,type)  => {


      if(type==='insurance'){
          console.log("insurance selected ="+event.target.value)

          let selection = event.target.value;
          //let selection = 'NO';
          console.log("selection value = "+selection);
         
          let tempstate = this.state;
          
          tempstate.insurance_details.insurance = event.target.value;

          if(event.target.value ==='YES') {
              console.log("I am evaludating YES");
              tempstate.cost_details.insurance_cost = ((tempstate.insurance_details.invoice_value*0.02)<=25) ? 25 : Math.ceil(tempstate.insurance_details.invoice_value*0.0255);
              //tempstate.cost_details.total_cost = (tempstate.cost_details.transportation_cost + tempstate.cost_details.insurance_cost);
              tempstate.cost_details.total_cost = ((parseFloat(tempstate.cost_details.transportation_cost)) + (parseFloat(tempstate.cost_details.insurance_cost)));
              /*this.setState(
                   tempstate
              )*/
          }

          if(event.target.value === 'NO') {
              console.log("I am evaludating NO");
              tempstate.cost_details.insurance_cost = 0;
              tempstate.cost_details.total_cost = (tempstate.cost_details.transportation_cost);
              /*this.setState(
                    tempstate
              )*/
          }


          this.setState(
              tempstate
          )
          
      }
      else if (type === 'invoicevalue') {
          console.log("invoice amount added =");

          
          let tempstate = this.state;
          tempstate.insurance_details.insurance = this.state.insurance_details.insurance;

          console.log("my value = "+tempstate.insurance_details.insurance);
          tempstate.insurance_details.invoice_value = event.target.value;
          tempstate.booking_flag[7] = 1;
          /*
          tempstate.cost_details.insurance_cost = (event.target.value*0.02); 

          this.setState(
              tempstate
          )
          */
          
          if(tempstate.insurance_details.insurance ==='YES') {
              console.log("I am inside value");
              tempstate.cost_details.insurance_cost = ((event.target.value*0.02)<=25) ? 25 : Math.ceil(tempstate.insurance_details.invoice_value*0.02);
              tempstate.cost_details.total_cost = ((parseFloat(tempstate.cost_details.transportation_cost)) + (parseFloat(tempstate.cost_details.insurance_cost)));
              this.setState(
                  tempstate
              )
          }
          else if (tempstate.insurance_details.insurance ==='NO'){
              tempstate.cost_details.total_cost = (tempstate.cost_details.transportation_cost);
              this.setState(
                  tempstate
              )
          }
         
          
           
          //tempstate.cost_details.insurance_cost = (0.02*event.target.value);
          
      }
      else if (type === 'goodsdesription'){
          let tempstate = this.state;
          tempstate.insurance_details.goods_description = event.target.value;
          tempstate.booking_flag[6] = 1; 
          this.setState(
              tempstate
          )
      }
      else if (type === 'shipmentreference') {
          let tempstate = this.state;
          tempstate.insurance_details.shipment_reference = event.target.value; 
          this.setState(
              tempstate
          ) 
      }
      console.log("event = "+event.target.value+"Type = "+type);
      /*
      let tempstate = this.state;
      
      tempstate.cost_details.insurance_cost = 25
      
      this.setState(
          tempstate
      )
      */

  }

  pickupdetailshandler = (event,type) => {
    let tempstate = this.state;
   
    if(type === 'contactperson') {
        console.log("I am in contact person ");
        tempstate.pickup_details.pickup_contact_person = event.target.value;
    }
    else if (type ==='contactnumber') {
        console.log("I am in contact number ")
        tempstate.pickup_details.pickup_contact_number = event.target.value;
    }
    else if (type ==='contactemail') {
        console.log("I am in contact Email ")
        tempstate.pickup_details.pickup_contact_email = event.target.value;
    }

    this.setState(
        tempstate
    )
      
  }

  deliverydetailshander = (event,type) => {

    let tempstate = this.state;

    if(type === 'contactperson') {
        console.log("I am in contact person ");
        tempstate.delivery_details.delivery_contact_person = event.target.value;
        tempstate.booking_flag[3] = 1;

    }
    else if (type ==='contactnumber') {
        console.log("I am in contact number ")
        tempstate.delivery_details.delivery_contact_number = event.target.value;
        tempstate.booking_flag[4] = 1;
    }
    else if (type ==='contactemail') {
        console.log("I am in contact Email ")
        tempstate.delivery_details.delivery_contact_email = event.target.value;
        tempstate.booking_flag[5] = 1;
    }
    else if (type ==='companyname') {
        //console.log("I am in contact Email ")
        tempstate.delivery_details.delivery_company = event.target.value;
        tempstate.booking_flag[0] = 1;
    } 
    else if (type ==='address1') {
        //console.log("I am in contact Email ")
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/;
            tempstate.delivery_details.delivery_address1 = event.target.value;
        var address1 = tempstate.delivery_details.delivery_address1;
        //var address2 = newstate.delivery_details.delivery_address2;

        if(format.test(address1)){
            //alert("Remove the speacil characters in the address field 1");
            Swal.fire({
                icon: 'error',
                title: 'speacil characters in the address field 1',
                text: 'please remove these speacil character in the address field and try again : ! @ # $ % ^ & * ( ) _ + - =',
                
              })
            tempstate.booking_flag[1] = 0;
        }
        else{
            tempstate.delivery_details.delivery_address1 = event.target.value;
            tempstate.booking_flag[1] = 1;    
        }
        
    }
    else if (type ==='address2') {
        //console.log("I am in contact Email ")

        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/;
            tempstate.delivery_details.delivery_address2 = event.target.value;
        var address2 = tempstate.delivery_details.delivery_address2;

        if(format.test(address2)){
            //alert("Remove the speacil characters in the address field 1");
            Swal.fire('Remove the speacil characters in the address field 2');
            tempstate.booking_flag[2] = 0;
        }
        else {
            tempstate.delivery_details.delivery_address2 = event.target.value;
            tempstate.booking_flag[2] = 1;
        }

        
    }
    
    this.setState(
        tempstate
    )
    console.log("details added = "+ event.target.value);

  }

 async bookshipmenthandler() {


    console.log("I am getting the Button Click");

    let newstate = this.state
    //let payload = (await Auth.currentSession()).getIdToken();

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/;

    var address1 = newstate.delivery_details.delivery_address1;
    var address2 = newstate.delivery_details.delivery_address2;

    if(format.test(address1)){
        alert("Remove the speacil characters in the address field 1")
        return;
    }

    if(format.test(address2)){
        alert("Remove the speacil characters in the address field 2");
        
        return;
    }


    if(newstate.insurance_details.invoice_value === '') {
        alert("Please add the invoice Value for this shipment"); 
        return;
    }
    else if(newstate.delivery_details.delivery_company ===''){
        alert('Please add the Delivery Company or Receiving person');
        return;
    }
    else if(newstate.delivery_details.delivery_address1 ===''){
        alert('Please add the Delivery Address 1');
        return;
    }
    else if(newstate.delivery_details.delivery_contact_person ===''){
        alert('Please add the Delivery Contact Person');
        return;
    } 
    else if(newstate.delivery_details.delivery_contact_number ===''){
        alert('Please add the Delivery Contact Number');
        return;
    }
    else {

            //alert("Button clicked");
        let data = {
            email: 'accounts@ronsenterprises.com',
            name: 'rajesh'
        }

        //let payload = (await Auth.currentSession()).getIdToken();

        //payload.operation = 'deduct_wallet';
        //payload.update_amount = this.state.cost_details.total_cost;

        //const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));

        //console.log("response = "+JSON.stringify(server_response.data));

        //console.log("Payload ="+JSON.stringify(payload.payload.email));

        // you need to know which courier is selected based on the same need to select the correct the booking link 
        // For DTDC it will be different url and fedex it will be a different url 

        this.props.history.push({
            pathname: '/showtracking',
            //querydata2 : tempstate
            querydata2 : newstate
        });
    }
    
    

}

async fetchmybalance() {

    let payload = (await Auth.currentSession()).getIdToken();
  
    payload.operation = 'wallet_balance';
    //payload.update_amount ='300';
  
    //console.log("IDtoken : "+JSON.stringify(payload));
  
    const server_response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload));
  
    console.log("response = "+JSON.stringify(server_response.data));
  
    let tempstate = this.state

    
    if( this.props.reversepickup === 'false'){
        tempstate.wallet_balance = server_response.data.Item.wallet_amount;
        tempstate.pickup_details.pickup_company = server_response.data.Item.Company_Name; 
        tempstate.pickup_details.pickup_address1 = server_response.data.Item.Default_address.address1; 
        tempstate.pickup_details.pickup_address2 = server_response.data.Item.Default_address.address2; 
        tempstate.pickup_details.pickup_zipcode = server_response.data.Item.Default_address.zipcode; 
        tempstate.pickup_details.pickup_place = server_response.data.Item.Default_address.place; 
        tempstate.pickup_details.pickup_state = server_response.data.Item.Default_address.state; 
        tempstate.pickup_details.pickup_contact_person = server_response.data.Item.Contact_Person; 
        tempstate.pickup_details.pickup_contact_number = server_response.data.Item.Contact_Number; 
        tempstate.pickup_details.pickup_contact_email = server_response.data.Item.Contact_Email; 

        tempstate.courier_localinfo = server_response.data.Item.Courier_contactinfo;
    
        this.setState(tempstate);
    }
    else {
        tempstate.wallet_balance = server_response.data.Item.wallet_amount;
        tempstate.pickup_details.pickup_company = server_response.data.Item.Company_Name; 
        tempstate.pickup_details.pickup_address1 = server_response.data.Item.Default_address.address1; 
        tempstate.pickup_details.pickup_address2 = server_response.data.Item.Default_address.address2; 
        tempstate.pickup_details.pickup_zipcode = this.props.reversepickupzip; 
        tempstate.pickup_details.pickup_place = this.props.reversepickupplace; 
        tempstate.pickup_details.pickup_state = this.props.reversepickupstate; 
        tempstate.pickup_details.pickup_contact_person = server_response.data.Item.Contact_Person; 
        tempstate.pickup_details.pickup_contact_number = server_response.data.Item.Contact_Number; 
        tempstate.pickup_details.pickup_contact_email = server_response.data.Item.Contact_Email; 

        tempstate.courier_localinfo = server_response.data.Item.Courier_contactinfo;
    
        this.setState(tempstate); 
    }

    
    /*
    this.setState = (
      {
        balance : server_response.data.Item.wallet_amount 
      }
    )
  */
    console.log("state value = "+JSON.stringify(this.state))
    //this.setState ()
  
  }

async componentDidMount() {
  
  const payload = this.props.location.querydata1;
  console.log("Data passed in summary= "+JSON.stringify(payload));

  console.log("Testting the store value = "+this.props.reversepickup)

  this.fetchmybalance();

  //payload = JSON.parse(payload);

  let pickupdetails,deliverydetails;
  //deliverydetails.delivery_place = payload.shipmentdata.deliveryplace;
  //deliverydetails.delivery_state = payload.shipmentdata.deliverystate;
  //deliverydetails.delivery_zipcode = payload.shipmentdata.deliveryzip;

  let tempstate = this.state;
  //tempstate.package_info = [];
  let temparray = [];

  temparray = payload.package_array;

  tempstate.delivery_details.delivery_state = payload.shipmentdata.deliverystate;
  tempstate.delivery_details.delivery_place = payload.shipmentdata.deliveryplace; 
  tempstate.delivery_details.delivery_zipcode = payload.shipmentdata.deliveryzip;
  tempstate.update = true
  tempstate.cost_details.transportation_cost = payload.rate_selected.price;
  tempstate.cost_details.insurance_cost = '0';
  tempstate.cost_details.total_cost = payload.rate_selected.price; 
  tempstate.package_info = temparray;
  tempstate.courier_info.courier_name =payload.rate_selected.courier;
  if(payload.rate_selected.mode ==="Heavy Weight"){
      console.log("I got heavy weight shipment");
      tempstate.courier_info.courier_type = 'Heavy Weight';
  }
  
  
  tempstate.courier_info.courier_mode =payload.rate_selected.type;
  tempstate.courier_info.chargable_weight =payload.rate_selected.chargableweight;
  //tempstate.package_info = [...payload.shipmentdata.package_info];
  console.log("package array ="+JSON.stringify(temparray))
  
  this.setState(
      tempstate
  )

  let temp = (await Auth.currentSession()).getIdToken();
  //console.log("temp = "+JSON.stringify(temp));
  console.log("temp = "+temp.payload.email);

}

PreviousPageHandler = () => {

    const payload = this.props.location.querydata2;

    this.props.history.push({
        pathname: '/allratescompare',
        //querydata1 : mytransfer_data
        querydata : payload
    });
}

    render (){

        let state_array = [];
            state_array = [...this.state.booking_flag];
        //let button_state = ""+state_array[0]+state_array[1]+state_array[2]+state_array[3]+state_array[4]+state_array[5]+state_array[6]+state_array[7]+state_array[8]+"";                            
        let button_state = ""+state_array[0]+state_array[1]+state_array[2]+state_array[3]+state_array[4]+state_array[5]+state_array[6]+state_array[7];                           

        return (
        <Aux>
        <div class="container-fluid pd-40">
            <div class="row">            
                {/*<p class="mb-4 ">Enter the Delivery Zip Code = {this.props.reversepickup}</p>*/}     
                <div class="d-flex justify-content-between mt-3">
                            {/*<button type="submit" class="btn btn-primary back-btn"></button>*/}
                            <p></p>
                            <Button className="float-right btn btn-primary back-btn" size="sm" onClick={this.PreviousPageHandler}>BACK</Button>
                    </div>
            </div>
            <div class="row mb-5">
                <Shipmentsummarybox2
            heading='Pick Up Details'
            pickup_company={this.state.pickup_details.pickup_company}
            pickup_address1 = {this.state.pickup_details.pickup_address1}
            pickup_address2 = {this.state.pickup_details.pickup_address2}
            pickup_zipcode = {this.state.pickup_details.pickup_zipcode}
            pickup_place = {this.state.pickup_details.pickup_place}
            pickup_state = {this.state.pickup_details.pickup_state}
            pickup_contactperson={this.state.pickup_details.pickup_contact_person}
            pickup_contactnumber={this.state.pickup_details.pickup_contact_number}
            pickup_contactemail={this.state.pickup_details.pickup_contact_email}
            pickup_contactdetails= {(e,type) => this.pickupdetailshandler(e,type)}

            />


            <Shipmentsummarybox1
                heading='Delivery Details'
                company={this.state.delivery_details.delivery_company}
                address1 = {this.state.delivery_details.delivery_address1}
                address2 = {this.state.delivery_details.delivery_address2}
                zipcode = {this.state.delivery_details.delivery_zipcode}
                place = {this.state.delivery_details.delivery_place}
                state = {this.state.delivery_details.delivery_state}
                contactperson={this.state.delivery_details.delivery_contact_person}
                contactnumber={this.state.delivery_details.delivery_contact_number}
                contactemail={this.state.delivery_details.delivery_contact_email}
                contactdetails= {(e,type) => this.deliverydetailshander(e,type)}
            />

            <p class="mb-4 mt-4 ">Enter the insurance details if you need insurance</p>

            <ShipmentInsurance
                invoicevalue = {(e,type) => this.insurancehandler(e,type)}
                insurance = {(e,type) => this.insurancehandler(e,type)}
            />


            <PaymentSummary
                cost= {this.state.cost_details.transportation_cost}
                insurance ={this.state.cost_details.insurance_cost}
                pickpcharge = "0"
                totalamount = {this.state.cost_details.total_cost}
            />

            {button_state}
            {((button_state == '11111111')||(button_state == '11111011')) ?

                <BookshipmentButton
                button_stat = {button_state}
                bookbutton = {() => this.bookshipmenthandler()}
                />
            
            : 
            
            <div className="d-flex justify-content-center mb-5 mt-4">
                <h2 align="ceneter">Enter all Required Details</h2>
                
            </div>
            }
        </div>
        
      </div>
        </Aux>
        );
    }

}

const mapStateToprops = state => {
    return {
        reversepickup : state.reversedelivery,
        reversepickupzip : state.reversePickupZip,
        reversepickupplace :state.reversePickupPlace,
        reversepickupstate : state.reversePickupState,
        walletamount : state.WalletBalance,
        wallet : state.totalprice
    };
  }
  
  

export default connect(mapStateToprops,null)(Shipmentsummary);
