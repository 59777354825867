import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import { jsPDF } from "jspdf";



class Test_printing extends Component {

    printing_js = () => {

        console.log("I am geting the hits");

        const doc = new jsPDF();

        
        doc.setFontSize(22);
        doc.text("DTDC", 90, 30);
        doc.setFontSize(16);

        doc.text("TRACKING NUMBER : ", 50, 50);
        doc.text("D76829283", 110, 50);

        doc.text("FROM", 10, 70);
        doc.text("Contact Person : ", 20, 80);
        doc.text("Rajesh Krishna", 65, 80);
        doc.text("Contact Number : ", 20, 90);
        doc.text("9400791900", 65, 90);
        doc.text("Adichira Thellakom P O", 20, 100);
        doc.text("Kottayam Kerala", 20, 110);
        doc.text("INDIA", 20, 120);


        doc.text("TO", 10, 140);
        doc.text("Contact Person : ", 20, 150);
        doc.text("Jayalakshmi", 65, 150);
        doc.text("Contact Number : ", 20, 160);
        doc.text("9400791900", 65, 160);
        doc.text("Adichira Thellakom P O", 20, 170);
        doc.text("Kottayam Kerala", 20, 180);
        doc.text("INDIA", 20, 190);
        
        doc.save("a4.pdf");

        

    }

    componentDidMount(){
        
    }

    render () {

        return (
            <Aux>
                <h1>hello</h1>
                <button onClick={this.printing_js}>Print PDF</button>
                <svg class="barcode"
  jsbarcode-format="upc"
  jsbarcode-value="123456789012"
  jsbarcode-textmargin="0"
  jsbarcode-fontoptions="bold">
</svg>
                
            </Aux>
        );

    }
}

export default Test_printing;

