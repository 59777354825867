import React, { Component } from 'react';
import Recharge from './Recharge';
//import Recharge from './Recharge_old';
import axios from 'axios';
import Amplify, { Auth,API } from 'aws-amplify';
import {Link} from 'react-router-dom';

const Razorpay = require('razorpay');

var instance = new Razorpay({
    //key_id: 'rzp_test_gGUOYhpc0xcq4a', //test mode
    key_id: 'rzp_live_OPL1SRUS2xZPtW', 
    //key_secret: 'fawpn96WDCnsysdH6oJG43v1' //test secret 
    key_secret: 'vLbsTP3JpWOjWBD7xo7S7b9K'
  })

  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}


class Recharge_modal extends Component {

    state = {
        recharge_amount: 0
    }

    
    rechargeamounthandler = (event) => {

        let amount = event.target.value;
        this.setState({
            recharge_amount : amount
        });
        //alert("Got the click from Payment button for payment = "+recharge_amount);
    } 

    async paymenthandler() {
        //alert("got the payment click ");
        //receipt is some kind of referece which we can see in razorpay dashboard we can keep it like 
        //customer_id-timestamp

        let idtoken = (await Auth.currentSession()).getIdToken();

        const payload = {
            amount : (this.state.recharge_amount * 100),
            currency : 'INR',
            receipt : '2564'

        };

        /*
        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/razorpayment',JSON.stringify(payload))
        .then(res => {
            console.log(res.data);


        })
        .catch(err => {

        });
*/



       const response =  await axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/razorpayment',JSON.stringify(payload));

       console.log(response.data);

       const order_id_from_razor = response.data.id;
       const amount_fromrazor = response.data.amount;

       const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
       //reference : https://dev.to/soumyadey/integrate-razorpay-in-your-react-app-2nib

        if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
        }
        
        const options = {
			//key: 'rzp_test_gGUOYhpc0xcq4a',  //test key
			key: 'rzp_live_OPL1SRUS2xZPtW',  // production key
			currency: 'INR',
			amount: amount_fromrazor,
			order_id: order_id_from_razor,
			name: 'Recharge Freight Meter Wallet',
			description: 'Thank you for using Freight Meter. Please add the money you want to recharge',
			image: 'https://www.freightmeter.in/Bootstrap5_assets/img/logo-white.png',
			handler: function (response) {
				alert(response.razorpay_payment_id)
				alert(response.razorpay_order_id)
                alert(response.razorpay_signature)

            //    const payload = {
            //        operation : 'update_wallet',
            //        update_amount : amount_fromrazor
            //    }

                let payload = idtoken;

                payload.operation = 'update_wallet';
                payload.update_amount = (amount_fromrazor/100);
                payload.razorpay_payment_id = response.razorpay_payment_id;
                payload.razorpay_order_id = response.razorpay_order_id;
                payload.razorpay_signature = response.razorpay_signature;

                //console.log("payload to update wallet : "+JSON.stringify(payload));


                axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/userinfo',JSON.stringify(payload))
                .then((res) => {
                    //
                    let update_wallet_amount = res.data.Item.wallet_amount;
                    
                    console.log("server has recorded the Balance = "+JSON.stringify(res.data));
                //    this.props.history.push({
                //        pathname: '/',
                //        updatebalance : update_wallet_amount
                //    });
                    window.location.replace("https://www.freightmeter.in/");
                    
                })
                .catch((err) => {

                })

                 
                
                
                

			},
			prefill: {
                //"name": "romy mathew",
                //"email": "gaurav.kumar@example.com",
                //"contact": "9999999999"
			}
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
        
    


    }

    render(){

        return(
            <div>
                <Recharge
                getrecharge_amount = {(e)=> this.rechargeamounthandler(e)}
                razor={(e) => this.paymenthandler()}
                />
            </div>
        );

    }
}

export default Recharge_modal;