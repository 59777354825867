import React from 'react';

const Shipmentsummarybox2 = (props) => {

    return (
      
        
      <div class="col-md-6 form-sec">
            
      <div class="card card2">
          <div class="container">
          <p>{props.heading}</p>
          <form>
          <label>Company</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_company} onChange={(e) => props.pickup_contactdetails(e,'companyname')}/>
          </div>
          
          <label>Address1</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_address1} onChange={(e) => props.pickup_contactdetails(e,'address1')}/>
          </div>
  
          <label>Address2</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_address2} onChange={(e) => props.pickup_contactdetails(e,'address2')}/>
          </div>
          <label>Zipcode</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_zipcode} readOnly/>
          </div>
  
          
  
          <label>Place</label>
          <div class="input-group">
          <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_place} readOnly/>
          
          </div>
  
          <label>State</label>
          <div class="input-group">
          <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_state} readOnly/>
          
          </div>
  
          <label>Contact Person</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactperson')} defaultValue={props.pickup_contactperson}/>
          </div>
          <label>Contact Number</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactnumber')} defaultValue={props.pickup_contactnumber}/>
          </div>
  
          <label>Contact Email</label>
          <div class="input-group deliver-pin">
          <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
          <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactemail')} defaultValue={props.pickup_contactemail}/>
          </div>
  
  
      </form>
  
          </div>
      </div>
  </div>

    
    );
}

export default Shipmentsummarybox2;

{/*
          <div class="col-md-6 form-sec">
              <div class="card">
                <div class="container">
                  <p>{props.heading}</p>
                  <form>
                  <label>Company</label>
                  <div class="input-group">
                    <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                    <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_company} onChange={(e) => props.pickup_contactdetails(e,'companyname')}/>
                  </div>

                  <label>Address1</label>
                  <div class="input-group">
                    <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                    <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_address1} onChange={(e) => props.pickup_contactdetails(e,'address1')}/>
                  </div>

                  <label>Address2</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_address2} onChange={(e) => props.pickup_contactdetails(e,'address2')}/>
                  </div>

                  <label>Zipcode</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_zipcode} readOnly/>
                  </div>

                  <label>Place</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
                  <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_place} readOnly/>
                  </div>

                  <label>State</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-science"></i></span>
                  <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" defaultValue={props.pickup_state} readOnly/>
                  </div>

                  <label>Contact Person</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactperson')} defaultValue={props.pickup_contactperson}/>
                  </div>

                  <label>Contact Number</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="tel" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactnumber')} defaultValue={props.pickup_contactnumber}/>
                  </div>

                  <label>Contact Email</label>
                  <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="email" class="form-control" placeholder="" aria-describedby="sizing-addon2" onChange={(e) => props.pickup_contactdetails(e,'contactemail')} defaultValue={props.pickup_contactemail}/>
                  </div>

                  </form>

                </div>
              </div>
    </div>*/}
