import React from 'react';

const PaymentSummary = (props) => {

    return (
      <div class="col-md-6 form-sec mt-4 mt-md-0">
            
      <div class="card card2">
        <div class="container">
        <p>Payment Summary</p>
        <form>
        <label>Transport Cost</label>
      <div class="input-group deliver-pin">
      <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
      <input type="text" class="form-control" placeholder="50" aria-describedby="sizing-addon2" defaultValue={props.cost} readOnly/>
      </div>
        
      <label>Insurance Cost</label>
      <div class="input-group deliver-pin">
      <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
      <input type="text" class="form-control" placeholder="50" aria-describedby="sizing-addon2" defaultValue={props.insurance} readOnly/>
      </div>

      <label>Pickup Cost</label>
      <div class="input-group deliver-pin">
      <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
      <input type="text" class="form-control" placeholder="50" aria-describedby="sizing-addon2" defaultValue={props.pickpcharge} readOnly/>
      </div>
      <label>Total Cost</label>
      <div class="input-group deliver-pin">
      <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
      <input type="text" class="form-control" placeholder="50" aria-describedby="sizing-addon2" defaultValue={props.totalamount} readOnly/>
      </div>

       



    </form>

      </div></div>
    </div>
    );
}

export default PaymentSummary;


{/*<div class="col-md-6 form-sec">
            <div class="card">
              <div class="container">
                <p>Payment Summary</p>
                <form>
                  <label>Transport Cost</label>
                <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="50" aria-describedby="sizing-addon2" defaultValue={props.cost} readOnly/>
                </div>

                <label>Insurance Cost</label>
                <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="0" aria-describedby="sizing-addon2" defaultValue={props.insurance} readOnly/>
                </div>
                <label>Pickup Cost</label>
                <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="0" aria-describedby="sizing-addon2" defaultValue={props.pickpcharge} readOnly/>
                </div>

                <label>Total Cost</label>
                <div class="input-group">
                  <span class="input-group-addon" id=""><i class="pe-7s-drawer"></i></span>
                  <input type="text" class="form-control" placeholder="0" aria-describedby="sizing-addon2" defaultValue={props.totalamount} readOnly/>
                </div>
                <div class="black-form-gap">
                </div>



                </form>



              </div>
            </div>


    </div>*/}