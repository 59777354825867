import React from 'react';

const Compareratebody = (props) => {

    return (
        <div>
            {/*<div class="card col-sm-6">
                <div class="container">
                    <div class="Courier-table brand-tb">

                        <div class="col-md-2 col-xs-4">
                            <div class="brand-logo">
                                <img src={props.logo} class="img-responsive"/>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-8">
                            <h2>{props.type}</h2>
                        </div>

                        <div class="col-md-2 col-xs-6">
                            <h3>Chargable Weight</h3>
                            <p>{props.chargeweight} KG</p>
                        </div>

                        <div class="col-md-2 col-xs-6">
                            <h3>Loading Date</h3>
                            <p>{props.loadingdate}</p>
                        </div>

                        <div class="col-md-2 col-xs-6">
                            <h3>Transit Days</h3>
                            <p>{props.transitdays}</p>
                        </div>

                        <div class="col-md-1 col-xs-6">
                            <h3>Price</h3>
                            <p><span>INR {props.price}</span></p>
                        </div>

                        <div class="col-md-1 col-xs-12">
                            <button onClick={() => props.submit(props.id)}>Book</button>
                        </div>

                    </div>
                </div>
            </div>*/}

                    <div class="col-md-6 col-lg-12 ">
                        <div class="row table-data-row mt-3">
                            <div class="col-lg col-6">
                                <div class="brand-img">
                                    <img src={props.logo} class="img-fluid"/>
                                </div>
                            </div>
                            <div class="col-lg col-6">
                                <div>
                                    <h4 class="courier-name">{props.type}</h4>
                                </div>
                            </div>
                            <div class="col-lg col-6 mt-4 mt-lg-0">
                                <div>
                                    <h3>Chargable Weight</h3>
                                    <p>{props.chargeweight} KG</p>
                                </div>
                            </div>
                            <div class="col-lg col-6 mt-4 mt-lg-0">
                                <div>
                                    <h3>Loading Date</h3>
                                    <p>{props.loadingdate}</p>
                                </div>
                            </div>
                            <div class="col-lg col-6 mt-4 mt-lg-0">
                                <div>
                                    <h3>Transit Days</h3>
                                    <p>{props.transitdays}</p>
                                </div>
                            </div>
                            <div class="col-lg col-6 mt-4 mt-lg-0">
                                <div>
                                    <h3>Price</h3>
                                    <p class="courier-status">{props.currencycode} {props.price}</p>
                                </div>
                            </div>
                            <div class="col-lg col-12 mt-4 mt-lg-0">
                                <button type="submit" class="btn btn-primary data-table-btn" onClick={() => props.submit(props.id)}>BOOK</button>
                            </div>
                        </div>
                    </div>

        </div>

           
     
);
}

export default Compareratebody;