import React from 'react';

const Contactus = (props) => {

    return (
        <div>
            <div class="container-fluid">
        
                <center><h1>Contact US</h1></center>
                <br/>
                <div class="container-fluid">
                    <center><h2>Ronsenterprises</h2></center>
                    <center><h2>Adichira Thellakom P O </h2></center>
                    <center><h2>Kottayam Kerala 686001 </h2></center>
                    <center><h2>INDIA</h2></center>
                    <br/>
                    <br/>

                    <center><h4>Email : sales@ronsenterprises.com</h4></center>
                    <center><h4>Mobile : 9400791900</h4></center>
                </div>
                

                </div>
        </div>
    );

}

export default Contactus;

