import React, { Component } from 'react';
import axios from 'axios';
import { Auth} from 'aws-amplify';

class Test_fedex_multiple_package extends Component {

    state = {
        pdfcopy : [],
        tracking_number : [],
        flag : 0
    }

    componentDidMount(){

        console.log(" I am going to Test the Fedex Mulitple package Booking");

        let payload = {
            "wallet_balance": "",
            "courier_info": {
            "courier_name": "Fedex",
            "courier_type": 'light',
            "courier_mode": "FEDEX_EXPRESS_SAVER",
            "chargable_weight": 2
            },
            "pickup_details": {
            "pickup_company": "Ronsenterprises",
            "pickup_address1": "Adichira",
            "pickup_address2": "Thellakom P O",
            "pickup_zipcode": "686630",
            "pickup_place": "adichira",
            "pickup_state": "Kerala",
            "pickup_contact_person": "Jayalakshmi",
            "pickup_contact_number": "9946516960",
            "pickup_contact_email": "office@ronsenterprises.com"
            },
            "delivery_details": {
            "delivery_company": "Hyatt Regency Mumabi",
            "delivery_address1": "Sahar Airport Rd",
            "delivery_address2": "Ashok Nagar, Andheri East",
            "delivery_zipcode": "560010",
            "delivery_place": "Industrial Estate (Bangalore)",
            "delivery_state": "Karnataka",
            "delivery_contact_person": "Kanojia",
            "delivery_contact_number": "944791800",
            "delivery_contact_email": "kanojia@gmail.com"
            },
            "package_info": [
            {
                "weight": "4",
                "length": "10",
                "width": "10",
                "height": "15",
                "packagetype": "Package"
            },
            {
                "weight": "8",
                "length": "15",
                "width": "12",
                "height": "19",
                "packagetype": "Package"    
            },
            {
                "weight": "12",
                "length": "18",
                "width": "22",
                "height": "10",
                "packagetype": "Package" 
            },
            {
                "weight": "22",
                "length": "28",
                "width": "25",
                "height": "15",
                "packagetype": "Package" 
            }, 
            ],
            "insurance_details": {
            "goods_description": "",
            "invoice_value": "2000",
            "shipment_reference": "",
            "insurance": "NO"
            },
            "cost_details": {
            "transportation_cost": "140.4",
            "insurance_cost": "0",
            "total_cost": "140.4"
            },
            "update": true,
            "registered_email": "2007.romy@gmail.com"
        }

        console.log("payload to send = "+JSON.stringify(payload));

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/fedex-booking-multiple-package',JSON.stringify(payload))
                .then(res => {
                    //let temp = res.config.data;
                    //let temp = JSON.parse(res.config.data);
                    //console.log("In the result page = "+res.data);
                    console.log("In the result page = "+JSON.stringify(res.data));

                    //let pdfcopy = res.data.pdfcopy;
                    //let trackingnumber = res.data.tracking_number;

                   
                    this.setState({
                        tracking_number: res.data.tracking_number,
                        pdfcopy : res.data.pdf_base64,
                        flag : 1

                    })
                    
                   
                    
                    
                    
                })
                .catch(err => {
                    console.log("some error happened");
                });
    }

    printpdfhandler = (index) =>{
      
        //let varriable = this.state.trial;
          console.log("going to fetch the state avalue "+ index)
          
        var pdfResult= this.state.pdfcopy[index];       
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
    
      }

    render() {

        const buttonarray = [''];
        //const buttonarray = this.state.pdfcopy

        const arraylength = buttonarray.length
                

        return(
            <div>
                <h3>Hello I am going to test fedex booking multiple package</h3>
                {buttonarray.map((row,index) => {
                    return(
                        <div>
                            {<button class="btn btn-primary print" onClick={() => this.printpdfhandler(index)}>Print</button>}
                        {/*<h2>I am hello {arraylength}</h2>*/}
                        </div>
                    );
                })}
            </div>
        );
    }

}

export default Test_fedex_multiple_package;