import React, { Component } from 'react';
import axios from 'axios';
import { Auth} from 'aws-amplify';

class Test_professional_rate extends Component {

  async  componentDidMount() {

        let payload = {
            deliveryplace: 'Whitefield',
            deliverydistrict:'Bangalaore',
            deliverystate: 'Karnataka',
            deliveryzip : '560001',
            pickupzip:'686001',
            pickupplace : 'Kottayam',
            pickupstate:'Kerala',
            package_count : '0',
            packageinfo: [
            {
                weight:'5',
                length:'15',
                width:'20',
                height:'25',
                packagetype:'Package' 
            }
            ],
            "update": true,
            "registered_email": "2007.romy@gmail.com"
        }

        const myInit = { 
            headers: { 
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
          }

        console.log("header = "+ JSON.stringify(myInit));

        axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/professionalcourier',JSON.stringify(payload),JSON.stringify(myInit))
        .then(res => {
            console.log(res.data);
            //console.log(res.data.body);
            
            
        })
        .catch(error => {
            console.log("some error happened Professional Courier Rate fetching");
        });


    }

    render() {

        return (
            <div>
                <h4>I am going to test Proffesional rate fetch</h4>
            </div>
        )
    }
}

export default Test_professional_rate;