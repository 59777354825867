/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:453d86c2-d5e9-4b0e-93d3-13f1cf7514aa",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_Q0WjDIy6u",
    "aws_user_pools_web_client_id": "52ormgifeqq52gvjp0ngc6i72h",
    "oauth": {}
};


export default awsmobile;
