import * as actionTypes from './actions';

const initialState = {
    WalletBalance  : 0,
    totalprice : 5,
    reversedelivery : 'false',
    reversePickupZip : '',
    reversePickupPlace : '',
    reversePickupState : '',
    WalletUpdate : true
}

const reducer = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.ADD_INGREDIENT :
            return {
                ...state,
                totalprice :  state.totalprice+1
            }

        case actionTypes.UPDATE_WALLET :
                return {
                    ...state,
                    WalletBalance : action.payload
                } 
        case actionTypes.UPDATE_REVERSEPICKUP :
            return {
                ...state,
                reversedelivery : action.payload
            }
        case actionTypes.UPDATE_REVERSE_ZIP :
            return {
                ...state,
                reversePickupZip : action.payload.zipcode,
                reversePickupPlace : action.payload.place,
                reversePickupState : action.payload.state 
            }
        default : 
            return state;
    }

};

export default reducer;
