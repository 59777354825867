import React from 'react';
import Aux from '../../../HOC/Aux';

const Selectpackage = (props) => {

    return (
        <Aux>
<div class="content">

<div class="container" id="pop-up-content">
    <div class="col-md-6" > 
        <div class="pop-img" id="option1">
            <img src={props.packageimage}/>   
        </div>

{/*
        <div class="pop-img" id="option2">
            <img src="assets/img/envelope.png"/>   
        </div>

        <div class="pop-img" id="option3">
            <img src="assets/img/package.png"/>   
        </div>

        <div class="pop-img" id="option4">
            <img src="assets/img/pallet.png"/>   
        </div>
*/}

    </div>
    

    <div class="col-md-6">

        <form>
   
        <div class="input-group">
                <label class="">Please Select Your Choice</label>
                <select class="selectpicker form-control" id="selectMe" onChange={(e) => props.packagetype(e)}>

                <option value="option1" defaultValue="selected">Shipping</option>
                <option value="Document">Document Envelope</option>
                <option value="Package">Package</option>
                <option value="pallet">Pallet</option>
                                    
            </select>
        </div>
        <br/>
            

    
        <div class="input-group">
        <button onClick={props.nextscreen}> Next Step</button>
        </div>

        </form>


    </div>
</div>

</div>

        </Aux>
    
    
    );
}

export default Selectpackage;