import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';
import axios from 'axios';
import Amplify, { Auth,API } from 'aws-amplify';
import Aux from '../../../HOC/Aux';
import Previewbody from './Previewbody';
import Spinner from '../CompareRates/Spinner';
const AWS = require('aws-sdk');



//const ID = 'AKIA2CBHMA6VUDVYAKKJ';
//const SECRET = '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';

const BUCKET_NAME = 'freightmeter';



const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

class Allshipmentpreview extends Component {


  state = {
    allshipmentdata : [],
    sortdata:[]
  }

  async componentDidMount(){

    //const payload = this.props.location.querydata2;
   
    let payload = (await Auth.currentSession()).getIdToken();

    axios.post('https://3bjgz48nth.execute-api.ap-south-1.amazonaws.com/dev/hello',JSON.stringify(payload))
        .then(res => {
            //let temp = res.config.data;
            //let temp = JSON.parse(res.config.data);
            console.log("Allshipmentdate = "+JSON.stringify(res.data));
            
            const arrayresult = res.data;
            this.setState({
                allshipmentdata : arrayresult,
                sortdata : arrayresult 
            });
        })
        .catch(err => {
            console.log("some error happened");
        });
    
       

    /*
   const apiName = 'myappapi5';
   const path = '/hello'; 
   const myInit = { // OPTIONAL
        headers: {
        "Content-Type":'application/json',
        //Authorization : 'token'
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }, // OPTIONAL
        queryStringParameters: {  // OPTIONAL
            name: 'param',
        }
   };

    API
    //.get('myappapi','/resource1')
    .get(apiName,path)
     .then(response => {
          // Add your code here
          console.log("my results = "+response);
    })
     .catch(error => {
        console.log(JSON.stringify(error.response));
    });

*/

  }

  sortshipment(event){
    console.log("got the request to sort the shipment ="+ event.target.value);

    //console.log("checking the ID ="+process.env.REACT_APP_WEATHER_API_KEY)
    //console.log("checking the AWS ID ="+process.env.REACT_APP_AWS_ID)
    //console.log("checking the AWS Secret ="+process.env.REACT_APP_AWS_SECRET)

    if(event.target.value==='LastMonth') {

      var currentYear= new Date().getFullYear(); 
      var currentMonth = new Date().getMonth();
      currentMonth = currentMonth-1;

      console.log("this year = "+currentYear+"==Month=="+currentMonth);  

      let allitems_array = [...this.state.allshipmentdata]

      let month_array = [];

      allitems_array.map((row,index) => {

        
        let row_date_year = row.Booking_date.match(/(\d{2})\/(\d{2})\/(\d{4})/)
        //console.log(row_date_year[3]);
        let tempyear = row_date_year[3];
        let tempmonth = row_date_year[1];
        let tempday = row_date_year[2];

        if(tempyear == currentYear){
          if(tempmonth == currentMonth) {
            month_array.push(row)
            console.log("I am in december");
          }
        }
      })

      console.log("length of month arrat = "+month_array.length);
      this.setState({
        sortdata : [...month_array]
      })


    }

    if(event.target.value==='Month') {
      var currentYear= new Date().getFullYear(); 
      var currentMonth = new Date().getMonth();
      currentMonth = currentMonth+1;

      console.log("this year = "+currentYear+"==Month=="+currentMonth);  

      let allitems_array = [...this.state.allshipmentdata]

      let month_array = [];

      allitems_array.map((row,index) => {

        
        let row_date_year = row.Booking_date.match(/(\d{2})\/(\d{2})\/(\d{4})/)
        //console.log(row_date_year[3]);
        let tempyear = row_date_year[3];
        let tempmonth = row_date_year[1];
        let tempday = row_date_year[2];

        if(tempyear == currentYear){
          if(tempmonth == currentMonth) {
            month_array.push(row)
            console.log("I am in december");
          }
        }
      })

      console.log("length of month arrat = "+month_array.length);
      this.setState({
        sortdata : [...month_array]
      })
    }

    if(event.target.value==='Day') {
      var currentYear= new Date().getFullYear(); 
      var currentMonth = new Date().getMonth();
      currentMonth = currentMonth+1;
      var currentday = new Date().getDate();

      console.log("this year = "+currentYear+"==Month=="+currentMonth+"===Day=="+currentday);  

      let allitems_array = [...this.state.allshipmentdata]

      let day_array = [];

      allitems_array.map((row,index) => {

        
        let row_date_year = row.Booking_date.match(/(\d{2})\/(\d{2})\/(\d{4})/)
        //console.log(row_date_year[3]);
        let tempyear = row_date_year[3];
        let tempmonth = row_date_year[1];
        let tempday = row_date_year[2];

        if(tempyear == currentYear){
          if(tempmonth == currentMonth) {
            if(tempday == currentday){
              day_array.push(row)
              console.log("I am in 5 december");
            }
            
          }
        }
      })

      console.log("length of Day array = "+day_array.length);
      this.setState({
        sortdata : [...day_array]
      })

    }

    if(event.target.value==='ALL'){

      this.setState({
        sortdata : [...this.state.allshipmentdata]
      })

    }
  }

  tracking_handler = (courier,trackingnumber) => {
    console.log("Tracking - "+courier);
    let actual_couriername = '';

    if(courier ==='DTDC'){
      actual_couriername = 'dtdc'
      //let link = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+trackingnumber
      let link = "https://trackcourier.io/track-and-trace/"+actual_couriername+"/"+trackingnumber
      window.open(link, "_blank") 
    }
    if(courier ==='Fedex'){
      actual_couriername = 'fedex-india'  
      let link = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+trackingnumber
      window.open(link, "_blank")
    }
    //let link = "https://trackcourier.io/track-and-trace/"+actual_couriername+"/"+trackingnumber
    //let link = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+trackingnumber
    //console.log("linke = "+link);
    //window.open(link, "_blank")
  }

  print_airwaybill = (tracking_number) => {

    console.log("Trakcing number given = "+tracking_number)

    const params = {
      Bucket: BUCKET_NAME,
      Key: tracking_number+'.pdf', // File name you want to save as in S3
      //Body: e.target.result
    };

    s3.getObject(params,function (error, data) {
      if (error != null) {
        console.log("Failed to retrieve an object: " + error);
      } else {
        console.log("Loaded " + data.ContentLength + " bytes");
        console.log("Data " + data.Body.toString());
        let newdata = data.Body.toString();
        let matchdata ='';
        //matchdata = newdata.match(/data\:application\/pdf;base64,(.*)/)
        //console.log("Matched data ="+matchdata[1])

        //let pdfResult = matchdata[1]; 
        let pdfResult = newdata; 
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
         
      }
    }
  );



  }

    render (){

      const awsresults = this.state.sortdata; 

        return(
            <Aux>
                <div class="container-fluid pd-40 pd-mob-20">
          <div class="row">
            <h6>Choose &amp; Book Courier Partner</h6>
            <div class="d-flex justify-content-between mt-3">
              <form>
                <select class="form-select ship-control" aria-label="Default select example" onChange={(e) => this.sortshipment(e)}>
                
                  <option value="ALL" defaultValue="All Shipment">All Shipment</option>
                  <option value="Month">Shipped this Month</option>
                  <option value="LastMonth">Shipped Last Month</option>
                  <option value="Day">Shipped Today</option>
                </select>
              </form>
                {/*<button type="submit" class="btn btn-primary back-btn"><Link to="/" class="nav-link">Back</Link></button>*/}
                
            </div>

            <div class="d-flex justify-content-between mt-3"> 
            <center>
            {(awsresults.length) ? '': <Spinner/>} 
            </center>
             
            </div>
          </div>


          {/*<!-- DATA TABLE START -->*/}


          {/*<!-- ROW1 -->*/}

        <div class="row mb-5">

            {awsresults.map((row) => {
            return (
                <Previewbody
                shipmentid={row.Booking_id}
                courier={row.Courier_company}
                mode={row.Courier_mode}
                trackingnumber = {row.Tracking_number}
                customername={row.Delivery_company}
                bookdate={row.Booking_date}
                status={row.Shipment_status}
                price={row.Total_cost}
                link = {(courier) => this.tracking_handler(courier,row.Tracking_number)}
                print = {(trackingnumber) => this.print_airwaybill(trackingnumber)}
                key={row.Booking_id}                                                                                                                                                
                />
            )                      
        })}

          
        </div>
        
      </div>
            </Aux>
        );
    }
}

export default Allshipmentpreview;